:root{
    --surface-a:#ffffff;
    --surface-b:#eff3f8;
    --surface-c:#f6f9fc;
    --surface-d:#dfe7ef;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --surface-0: #ffffff;
    --surface-50: #f1f4f6;
    --surface-100: #e2e8ee;
    --surface-200: #c6d1dd;
    --surface-300: #a9bbcb;
    --surface-400: #8da4ba;
    --surface-500: #708da9;
    --surface-600: #5a7187;
    --surface-700: #435565;
    --surface-800: #2d3844;
    --surface-900: #161c22;
    --gray-50: #f1f4f6;
    --gray-100: #e2e8ee;
    --gray-200: #c6d1dd;
    --gray-300: #a9bbcb;
    --gray-400: #8da4ba;
    --gray-500: #708da9;
    --gray-600: #5a7187;
    --gray-700: #435565;
    --gray-800: #2d3844;
    --gray-900: #161c22;
    --content-padding:1.25rem;
    --inline-spacing:0.5rem;
    --border-radius:6px;
    --surface-ground:#eff3f8;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dfe7ef;
    --surface-hover:#f6f9fc;
    --maskbg: rgba(0, 0, 0, 0.4);
    --focus-ring: 0 0 0 1px #c7bbfa;
    --highlight-bg: #e2dcfc;
    --highlight-text-color: #7254f3;
    color-scheme:light
}

.p-component{
    font-size:1rem;
    font-weight:normal
}
.p-component-overlay{
    background-color:rgba(0,0,0,.4);
    transition-duration:.2s
}
.p-disabled,.p-component:disabled{
    opacity:.6
}
.p-error{
    color:#ff6767
}
.p-text-secondary{
    color:#708da9
}
.pi{
    font-size:1rem
}
.p-icon{
    width:1rem;
    height:1rem
}
.p-link{
    font-size:1rem;
    border-radius:6px
}
.p-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-component-overlay-enter{
    animation:p-component-overlay-enter-animation 150ms forwards
}
.p-component-overlay-leave{
    animation:p-component-overlay-leave-animation 150ms forwards
}
@keyframes p-component-overlay-enter-animation{
    from{
        background-color:transparent
    }
    to{
        background-color:var(--maskbg)
    }
}
@keyframes p-component-overlay-leave-animation{
    from{
        background-color:var(--maskbg)
    }
    to{
        background-color:transparent
    }
}
:root{
    --blue-50:#f6fbfe;
    --blue-100:#d6ecfc;
    --blue-200:#b5ddfa;
    --blue-300:#95cef8;
    --blue-400:#74bff5;
    --blue-500:#54b0f3;
    --blue-600:#4796cf;
    --blue-700:#3b7baa;
    --blue-800:#2e6186;
    --blue-900:#224661;
    --green-50:#f4fcf8;
    --green-100:#ccf2dc;
    --green-200:#a3e7c1;
    --green-300:#7adca6;
    --green-400:#52d28a;
    --green-500:#29c76f;
    --green-600:#23a95e;
    --green-700:#1d8b4e;
    --green-800:#176d3d;
    --green-900:#10502c;
    --yellow-50:#fefdf6;
    --yellow-100:#fcf4d6;
    --yellow-200:#faebb5;
    --yellow-300:#f8e295;
    --yellow-400:#f5d974;
    --yellow-500:#f3d054;
    --yellow-600:#cfb147;
    --yellow-700:#aa923b;
    --yellow-800:#86722e;
    --yellow-900:#615322;
    --cyan-50:#f5fcfd;
    --cyan-100:#d1f2f5;
    --cyan-200:#ace8ed;
    --cyan-300:#87dee6;
    --cyan-400:#63d3de;
    --cyan-500:#3ec9d6;
    --cyan-600:#35abb6;
    --cyan-700:#2b8d96;
    --cyan-800:#226f76;
    --cyan-900:#195056;
    --pink-50:#fef6f7;
    --pink-100:#fad6d6;
    --pink-200:#f6b5b6;
    --pink-300:#f29596;
    --pink-400:#ee7475;
    --pink-500:#ea5455;
    --pink-600:#c74748;
    --pink-700:#a43b3c;
    --pink-800:#812e2f;
    --pink-900:#5e2222;
    --indigo-50:#f6f7fe;
    --indigo-100:#d6dafc;
    --indigo-200:#b5bcfa;
    --indigo-300:#959ff8;
    --indigo-400:#7481f5;
    --indigo-500:#5464f3;
    --indigo-600:#4755cf;
    --indigo-700:#3b46aa;
    --indigo-800:#2e3786;
    --indigo-900:#222861;
    --teal-50:#f3fcf9;
    --teal-100:#c5f1e1;
    --teal-200:#97e5c9;
    --teal-300:#69dab1;
    --teal-400:#3bce99;
    --teal-500:#0dc381;
    --teal-600:#0ba66e;
    --teal-700:#09895a;
    --teal-800:#076b47;
    --teal-900:#054e34;
    --orange-50:#fffaf6;
    --orange-100:#ffe8d2;
    --orange-200:#ffd6ae;
    --orange-300:#ffc38a;
    --orange-400:#ffb166;
    --orange-500:#ff9f42;
    --orange-600:#d98738;
    --orange-700:#b36f2e;
    --orange-800:#8c5724;
    --orange-900:#66401a;
    --bluegray-50:#f7f8fa;
    --bluegray-100:#dbdfe8;
    --bluegray-200:#bec5d6;
    --bluegray-300:#a1abc4;
    --bluegray-400:#8592b2;
    --bluegray-500:#6878a0;
    --bluegray-600:#586688;
    --bluegray-700:#495470;
    --bluegray-800:#394258;
    --bluegray-900:#2a3040;
    --purple-50:#f8f6fe;
    --purple-100:#ddd6fc;
    --purple-200:#c2b5fa;
    --purple-300:#a895f8;
    --purple-400:#8d74f5;
    --purple-500:#7254f3;
    --purple-600:#6147cf;
    --purple-700:#503baa;
    --purple-800:#3f2e86;
    --purple-900:#2e2261;
    --red-50:#fff5f6;
    --red-100:#ffcfd4;
    --red-200:#ffa9b2;
    --red-300:#ff848f;
    --red-400:#ff5e6d;
    --red-500:#ff384b;
    --red-600:#d93040;
    --red-700:#b32735;
    --red-800:#8c1f29;
    --red-900:#66161e;
    --primary-50:#f8f6fe;
    --primary-100:#ddd6fc;
    --primary-200:#c2b5fa;
    --primary-300:#a895f8;
    --primary-400:#8d74f5;
    --primary-500:#7254f3;
    --primary-600:#6147cf;
    --primary-700:#503baa;
    --primary-800:#3f2e86;
    --primary-900:#2e2261
}
.p-autocomplete .p-autocomplete-loader{
    right:.75rem
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader{
    right:3.75rem
}
.p-autocomplete .p-autocomplete-multiple-container{
    padding:.375rem .75rem;
    gap:.5rem
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token{
    padding:.375rem 0
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input{
    font-size:1rem;
    color:#043d75;
    padding:0;
    margin:0
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token{
    padding:.375rem .75rem;
    background:#e2dcfc;
    color:#7254f3;
    border-radius:6px
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon{
    margin-left:.5rem
}
p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext{
    border-color:#ff6767
}
.p-autocomplete-panel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-autocomplete-panel .p-autocomplete-items{
    padding:.75rem 0
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
    margin:0;
    padding:.75rem 1.25rem;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s;
    border-radius:0
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover{
    color:#043d75;
    background:#f6f9fc
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75;
    background:transparent
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700
}
p-autocomplete.p-autocomplete-clearable .p-inputtext{
    padding-right:2.5rem
}
p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon{
    color:#708da9;
    right:.75rem
}
p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon{
    color:#708da9;
    right:3.75rem
}
p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext{
    border-color:#ff6767
}
.p-datepicker{
    padding:.5rem;
    background:linear-gradient(90deg, #7254f3 0%, #9554f3 100%);
    color:#fff;
    border:0 none;
    border-radius:6px
}
.p-datepicker:not(.p-datepicker-inline){
    background:linear-gradient(90deg, #7254f3 0%, #9554f3 100%);
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header{
    background:transparent
}
.p-datepicker .p-datepicker-header{
    padding:.5rem;
    color:#fff;
    background:linear-gradient(90deg, #7254f3 0%, #9554f3 100%);
    font-weight:700;
    margin:0;
    border-bottom:1px solid rgba(255,255,255,.2);
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,.p-datepicker .p-datepicker-header .p-datepicker-next{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,.p-datepicker .p-datepicker-header .p-datepicker-next:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datepicker .p-datepicker-header .p-datepicker-title{
    line-height:2rem
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month{
    color:#fff;
    transition:background-color .2s,color .2s,box-shadow .2s;
    font-weight:700;
    padding:.5rem
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover{
    color:#7254f3
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month{
    margin-right:.5rem
}
.p-datepicker table{
    font-size:1rem;
    margin:.5rem 0
}
.p-datepicker table th{
    padding:.5rem
}
.p-datepicker table th>span{
    width:2.5rem;
    height:2.5rem
}
.p-datepicker table td{
    padding:.5rem
}
.p-datepicker table td>span{
    width:2.5rem;
    height:2.5rem;
    border-radius:50%;
    transition:box-shadow .2s;
    border:1px solid transparent
}
.p-datepicker table td>span.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-datepicker table td>span:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datepicker table td.p-datepicker-today>span{
    background:rgba(255,255,255,.1);
    color:#fff;
    border-color:transparent
}
.p-datepicker table td.p-datepicker-today>span.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-datepicker .p-datepicker-buttonbar{
    padding:1rem 0;
    border-top:1px solid #dfe7ef
}
.p-datepicker .p-datepicker-buttonbar .p-button{
    width:auto
}
.p-datepicker .p-timepicker{
    border-top:1px solid #dfe7ef;
    padding:.5rem
}
.p-datepicker .p-timepicker button{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-datepicker .p-timepicker button:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-datepicker .p-timepicker button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datepicker .p-timepicker button:last-child{
    margin-top:.2em
}
.p-datepicker .p-timepicker span{
    font-size:1.25rem
}
.p-datepicker .p-timepicker>div{
    padding:0 .5rem
}
.p-datepicker.p-datepicker-timeonly .p-timepicker{
    border-top:0 none
}
.p-datepicker .p-monthpicker{
    margin:.5rem 0
}
.p-datepicker .p-monthpicker .p-monthpicker-month{
    padding:.5rem;
    transition:box-shadow .2s;
    border-radius:6px
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-datepicker .p-yearpicker{
    margin:.5rem 0
}
.p-datepicker .p-yearpicker .p-yearpicker-year{
    padding:.5rem;
    transition:box-shadow .2s;
    border-radius:6px
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group{
    border-left:1px solid #dfe7ef;
    padding-right:.5rem;
    padding-left:.5rem;
    padding-top:0;
    padding-bottom:0
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child{
    padding-left:0;
    border-left:0 none
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child{
    padding-right:0
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover{
    background:rgba(255,255,255,.2)
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover{
    background:rgba(255,255,255,.2)
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover{
    background:rgba(255,255,255,.2)
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
p-calendar.p-calendar-clearable .p-inputtext{
    padding-right:2.5rem
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon{
    color:#708da9;
    right:.75rem
}
p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon{
    color:#708da9;
    right:3.75rem
}
@media screen and (max-width: 769px){
    .p-datepicker table th,.p-datepicker table td{
        padding:0
    }
}
.p-cascadeselect{
    background:#fff;
    border:1px solid #d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.p-cascadeselect:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-cascadeselect:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-cascadeselect .p-cascadeselect-label{
    background:transparent;
    border:0 none;
    padding:.75rem .75rem
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder{
    color:#708da9
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus{
    outline:0 none;
    box-shadow:none
}
.p-cascadeselect .p-cascadeselect-trigger{
    background:transparent;
    color:#708da9;
    width:3rem;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.p-cascadeselect-panel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-cascadeselect-panel .p-cascadeselect-items{
    padding:.75rem 0
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item{
    margin:0;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s;
    border-radius:0
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content{
    padding:.75rem 1.25rem
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover{
    color:#043d75;
    background:#f6f9fc
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon{
    font-size:.875rem
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-icon{
    width:.875rem;
    height:.875rem
}
p-cascadeselect.ng-dirty.ng-invalid>.p-cascadeselect{
    border-color:#ff6767
}
.p-input-filled .p-cascadeselect{
    background:#f6f9fc
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover{
    background-color:#f6f9fc
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus{
    background-color:#fff
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label{
    padding-right:.75rem
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon{
    color:#708da9;
    right:3rem
}
.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel{
    box-shadow:none;
    border-radius:0;
    padding:.25rem 0 .25rem .5rem
}
.p-overlay-modal .p-cascadeselect-item-active>.p-cascadeselect-item-content .p-cascadeselect-group-icon{
    transform:rotate(90deg)
}
.p-checkbox{
    width:22px;
    height:22px
}
.p-checkbox .p-checkbox-box{
    border:2px solid #d3dbe3;
    background:#fff;
    width:22px;
    height:22px;
    color:#043d75;
    border-radius:6px;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-checkbox .p-checkbox-box .p-checkbox-icon{
    transition-duration:.2s;
    color:#fff;
    font-size:14px
}
.p-checkbox .p-checkbox-box .p-icon{
    width:14px;
    height:14px
}
.p-checkbox .p-checkbox-box.p-highlight{
    border-color:#7254f3;
    background:#7254f3
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color:#7254f3
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    border-color:#5935f1;
    background:#5935f1;
    color:#fff
}
p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box{
    border-color:#ff6767
}
.p-input-filled .p-checkbox .p-checkbox-box{
    background-color:#f6f9fc
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight{
    background:#7254f3
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    background-color:#f6f9fc
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    background:#5935f1
}
.p-checkbox-label{
    margin-left:.5rem
}
.p-chips .p-chips-multiple-container{
    padding:.375rem .75rem;
    gap:.5rem
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-chips .p-chips-multiple-container .p-chips-token{
    padding:.375rem .75rem;
    background:#e2dcfc;
    color:#7254f3;
    border-radius:6px
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon{
    margin-left:.5rem
}
.p-chips .p-chips-multiple-container .p-chips-input-token{
    padding:.375rem 0
}
.p-chips .p-chips-multiple-container .p-chips-input-token input{
    font-size:1rem;
    color:#043d75;
    padding:0;
    margin:0
}
p-chips.ng-dirty.ng-invalid>.p-chips>.p-inputtext{
    border-color:#ff6767
}
p-chips.p-chips-clearable .p-inputtext{
    padding-right:1.75rem
}
p-chips.p-chips-clearable .p-chips-clear-icon{
    color:#708da9;
    right:.75rem
}
.p-colorpicker-preview,.p-fluid .p-colorpicker-preview.p-inputtext{
    width:2rem;
    height:2rem
}
.p-colorpicker-panel{
    background:#323232;
    border:1px solid #191919
}
.p-colorpicker-panel .p-colorpicker-color-handle,.p-colorpicker-panel .p-colorpicker-hue-handle{
    border-color:#fff
}
.p-colorpicker-overlay-panel{
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-dropdown{
    background:#fff;
    border:1px solid #d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.p-dropdown:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-dropdown:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label{
    padding-right:1.75rem
}
.p-dropdown .p-dropdown-label{
    background:transparent;
    border:0 none
}
.p-dropdown .p-dropdown-label.p-placeholder{
    color:#708da9
}
.p-dropdown .p-dropdown-label:enabled:focus{
    outline:0 none;
    box-shadow:none
}
.p-dropdown .p-dropdown-trigger{
    background:transparent;
    color:#708da9;
    width:3rem;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.p-dropdown .p-dropdown-clear-icon{
    color:#708da9;
    right:3rem
}
.p-dropdown-panel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-dropdown-panel .p-dropdown-header{
    padding:.75rem 1.25rem;
    border-bottom:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    margin:0;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter{
    padding-right:1.75rem;
    margin-right:-1.75rem
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-dropdown-panel .p-dropdown-items{
    padding:.75rem 0
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    margin:0;
    padding:.75rem 1.25rem;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s;
    border-radius:0
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover{
    color:#043d75;
    background:#f6f9fc
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75;
    background:transparent
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700
}
p-dropdown.ng-dirty.ng-invalid>.p-dropdown{
    border-color:#ff6767
}
.p-input-filled .p-dropdown{
    background:#f6f9fc
}
.p-input-filled .p-dropdown:not(.p-disabled):hover{
    background-color:#f6f9fc
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus{
    background-color:#fff
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext{
    background-color:transparent
}
.p-editor-container .p-editor-toolbar{
    background:#eff3f8;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-editor-container .p-editor-toolbar.ql-snow{
    border:1px solid #dfe7ef
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke{
    stroke:#708da9
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill{
    fill:#708da9
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label{
    border:0 none;
    color:#708da9
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover{
    color:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke{
    stroke:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill{
    fill:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label{
    color:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke{
    stroke:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill{
    fill:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    background:#fff;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px;
    padding:.75rem 0
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item{
    color:#043d75
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover{
    color:#043d75;
    background:#f6f9fc
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item{
    padding:.75rem 1.25rem
}
.p-editor-container .p-editor-content{
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-editor-container .p-editor-content.ql-snow{
    border:1px solid #dfe7ef
}
.p-editor-container .p-editor-content .ql-editor{
    background:#fff;
    color:#043d75;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-editor-container .ql-snow.ql-toolbar button:hover,.p-editor-container .ql-snow.ql-toolbar button:focus{
    color:#043d75
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke{
    stroke:#043d75
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill{
    fill:#043d75
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected{
    color:#7254f3
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke{
    stroke:#7254f3
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill{
    fill:#7254f3
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label{
    color:#7254f3
}
.p-inputgroup-addon{
    background:#f6f9fc;
    color:#708da9;
    border-top:1px solid #d3dbe3;
    border-left:1px solid #d3dbe3;
    border-bottom:1px solid #d3dbe3;
    padding:.75rem .75rem;
    min-width:3rem
}
.p-inputgroup-addon:last-child{
    border-right:1px solid #d3dbe3
}
.p-inputgroup>.p-component,.p-inputgroup>.p-element,.p-inputgroup>.p-inputwrapper>.p-component>.p-inputtext,.p-inputgroup>.p-float-label>.p-component{
    border-radius:0;
    margin:0
}
.p-inputgroup>.p-component+.p-inputgroup-addon,.p-inputgroup>.p-element+.p-inputgroup-addon,.p-inputgroup>.p-inputwrapper>.p-component>.p-inputtext+.p-inputgroup-addon,.p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon{
    border-left:0 none
}
.p-inputgroup>.p-component:focus,.p-inputgroup>.p-element:focus,.p-inputgroup>.p-inputwrapper>.p-component>.p-inputtext:focus,.p-inputgroup>.p-float-label>.p-component:focus{
    z-index:1
}
.p-inputgroup>.p-component:focus~label,.p-inputgroup>.p-element:focus~label,.p-inputgroup>.p-inputwrapper>.p-component>.p-inputtext:focus~label,.p-inputgroup>.p-float-label>.p-component:focus~label{
    z-index:1
}
.p-inputgroup-addon:first-child,.p-inputgroup button:first-child,.p-inputgroup input:first-child,.p-inputgroup>.p-inputwrapper:first-child>.p-component,.p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px
}
.p-inputgroup .p-float-label:first-child input{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px
}
.p-inputgroup-addon:last-child,.p-inputgroup button:last-child,.p-inputgroup input:last-child,.p-inputgroup>.p-inputwrapper:last-child>.p-component,.p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.p-inputgroup .p-float-label:last-child input{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.p-fluid .p-inputgroup .p-button{
    width:auto
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only{
    width:3rem
}
p-inputmask.ng-dirty.ng-invalid>.p-inputtext{
    border-color:#ff6767
}
p-inputmask.p-inputmask-clearable .p-inputtext{
    padding-right:2.5rem
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon{
    color:#708da9;
    right:.75rem
}
p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext{
    border-color:#ff6767
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-input{
    padding-right:2.5rem
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon{
    color:#708da9;
    right:.75rem
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon{
    right:3.75rem
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon{
    right:3.75rem
}
.p-inputswitch{
    width:3rem;
    height:1.75rem
}
.p-inputswitch .p-inputswitch-slider{
    background:#d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:30px
}
.p-inputswitch .p-inputswitch-slider:before{
    background:#fff;
    width:1.25rem;
    height:1.25rem;
    left:.25rem;
    margin-top:-0.625rem;
    border-radius:50%;
    transition-duration:.2s
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before{
    transform:translateX(1.25rem)
}
.p-inputswitch.p-focus .p-inputswitch-slider{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider{
    background:#b8c5d2
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#7254f3
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before{
    background:#fff
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
    background:#6545f2
}
p-inputswitch.ng-dirty.ng-invalid>.p-inputswitch{
    border-color:#ff6767
}
.p-inputtext{
    font-size:1rem;
    background:#fff;
    padding:.75rem .75rem;
    border:1px solid #d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    appearance:none;
    border-radius:6px
}
.p-inputtext:enabled:hover{
    border-color: var(--blue-900)
}
.p-inputtext:enabled:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px var(--blue-900);
    border-color: var(--blue-900)
}
.p-inputtext.ng-dirty.ng-invalid{
    border-color:#ff6767;
    box-shadow: 0 0 0 1px #f4433666;
}
.p-inputtext.p-inputtext-sm{
    font-size:.875rem;
    padding:.65625rem .65625rem
}
.p-inputtext.p-inputtext-lg{
    font-size:1.25rem;
    padding:.9375rem .9375rem
}
.p-float-label>label{
    left:.75rem;
    color: #999999;
    transition-duration:.2s
}
.p-float-label>.ng-invalid.ng-dirty+label{
    color:#ff6767
}
.p-input-icon-left>.p-icon-wrapper.p-icon,.p-input-icon-left>i:first-of-type{
    left:.75rem;
    color:#708da9
}
.p-input-icon-left>.p-inputtext{
    padding-left:2.5rem
}
.p-input-icon-left.p-float-label>label{
    left:2.5rem
}
.p-input-icon-right>.p-icon-wrapper,.p-input-icon-right>i:last-of-type{
    right:.75rem;
    color:#708da9
}
.p-input-icon-right>.p-inputtext{
    padding-right:2.5rem
}
::-webkit-input-placeholder{
    color:#708da9
}
:-moz-placeholder{
    color:#708da9
}
::-moz-placeholder{
    color:#708da9
}
:-ms-input-placeholder{
    color:#708da9
}
.p-input-filled .p-inputtext{
    background-color:#f6f9fc
}
.p-input-filled .p-inputtext:enabled:hover{
    background-color:#f6f9fc
}
.p-input-filled .p-inputtext:enabled:focus{
    background-color:#fff
}
.p-inputtext-sm .p-inputtext{
    font-size:.875rem;
    padding:.65625rem .65625rem
}
.p-inputtext-lg .p-inputtext{
    font-size:1.25rem;
    padding:.9375rem .9375rem
}
.p-listbox{
    background:#fff;
    color:#043d75;
    border:1px solid #d3dbe3;
    border-radius:6px
}
.p-listbox .p-listbox-header{
    padding:.75rem 1.25rem;
    border-bottom:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    margin:0;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-listbox .p-listbox-header .p-listbox-filter{
    padding-right:1.75rem
}
.p-listbox .p-listbox-header .p-listbox-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-listbox .p-listbox-header .p-checkbox{
    margin-right:.5rem
}
.p-listbox .p-listbox-list{
    padding:.75rem 0
}
.p-listbox .p-listbox-list .p-listbox-item{
    margin:0;
    padding:.75rem 1.25rem;
    border:0 none;
    color:#043d75;
    transition:box-shadow .2s;
    border-radius:0
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-listbox .p-listbox-list .p-listbox-item:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox{
    margin-right:.5rem
}
.p-listbox .p-listbox-list .p-listbox-item-group{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700
}
.p-listbox .p-listbox-list .p-listbox-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75;
    background:transparent
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover{
    color:#043d75;
    background:#f6f9fc
}
p-listbox.ng-dirty.ng-invalid>.p-listbox{
    border-color:#ff6767
}
.p-multiselect{
    background:#fff;
    border:1px solid #d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.p-multiselect:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-multiselect:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-multiselect .p-multiselect-label{
    padding:.75rem .75rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-multiselect .p-multiselect-label.p-placeholder{
    color:#708da9
}
.p-multiselect.p-multiselect-chip .p-multiselect-label{
    gap:.5rem
}
.p-multiselect.p-multiselect-chip .p-multiselect-token{
    padding:.375rem .75rem;
    background:#e2dcfc;
    color:#7254f3;
    border-radius:6px
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon{
    margin-left:.5rem
}
.p-multiselect .p-multiselect-trigger{
    background:transparent;
    color:#708da9;
    width:3rem;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label{
    padding:.375rem .75rem
}
.p-multiselect-panel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-multiselect-panel .p-multiselect-header{
    padding:.75rem 1.25rem;
    border-bottom:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    margin:0;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext{
    padding-right:1.75rem
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-multiselect-panel .p-multiselect-header .p-checkbox{
    margin-right:.5rem
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close{
    margin-left:.5rem;
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-multiselect-panel .p-multiselect-items{
    padding:.75rem 0
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    margin:0;
    padding:.75rem 1.25rem;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s;
    border-radius:0
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover{
    color:#043d75;
    background:#f6f9fc
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox{
    margin-right:.5rem
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75;
    background:transparent
}
p-multiselect.ng-dirty.ng-invalid>.p-multiselect{
    border-color:#ff6767
}
.p-input-filled .p-multiselect{
    background:#f6f9fc
}
.p-input-filled .p-multiselect:not(.p-disabled):hover{
    background-color:#f6f9fc
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus{
    background-color:#fff
}
p-multiselect.p-multiselect-clearable .p-multiselect-label-container{
    padding-right:1.75rem
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon{
    color:#708da9;
    right:3rem
}
p-password.ng-invalid.ng-dirty>.p-password>.p-inputtext{
    border-color:#ff6767
}
.p-password-panel{
    padding:1.25rem;
    background:#fff;
    color:#043d75;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px
}
.p-password-panel .p-password-meter{
    margin-bottom:.5rem;
    background:#dfe7ef
}
.p-password-panel .p-password-meter .p-password-strength.weak{
    background:#ea5455
}
.p-password-panel .p-password-meter .p-password-strength.medium{
    background:#ff9f42
}
.p-password-panel .p-password-meter .p-password-strength.strong{
    background:#29c76f
}
p-password.p-password-clearable .p-password-input{
    padding-right:2.5rem
}
p-password.p-password-clearable .p-password-clear-icon{
    color:#708da9;
    right:.75rem
}
p-password.p-password-clearable.p-password-mask .p-password-input{
    padding-right:4.25rem
}
p-password.p-password-clearable.p-password-mask .p-password-clear-icon{
    color:#708da9;
    right:2.5rem
}
.p-radiobutton{
    width:22px;
    height:22px
}
.p-radiobutton .p-radiobutton-box{
    border:2px solid #d3dbe3;
    background:#fff;
    width:22px;
    height:22px;
    color:#043d75;
    border-radius:50%;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover{
    border-color:#7254f3
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
    width:12px;
    height:12px;
    transition-duration:.2s;
    background-color:#fff
}
.p-radiobutton .p-radiobutton-box.p-highlight{
    border-color:#7254f3;
    background:#7254f3
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover{
    border-color:#5935f1;
    background:#5935f1;
    color:#fff
}
p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box{
    border-color:#ff6767
}
.p-input-filled .p-radiobutton .p-radiobutton-box{
    background-color:#f6f9fc
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover{
    background-color:#f6f9fc
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight{
    background:#7254f3
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover{
    background:#5935f1
}
.p-radiobutton-label{
    margin-left:.5rem
}
.p-rating{
    gap:.5rem
}
.p-rating .p-rating-icon{
    color:#043d75;
    margin-left:.5rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    font-size:1.143rem
}
.p-rating .p-rating-icon.p-icon{
    width:1.143rem;
    height:1.143rem
}
.p-rating .p-rating-icon.p-rating-cancel{
    color:#ea5455
}
.p-rating .p-rating-icon:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-rating .p-rating-icon:first-child{
    margin-left:0
}
.p-rating .p-rating-icon.p-rating-icon-active{
    color:#7254f3
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover{
    color:#7254f3
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover{
    color:#e73d3e
}
.p-selectbutton .p-button{
    background:#fff;
    border:1px solid #d3dbe3;
    color:#043d75;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-selectbutton .p-button .p-button-icon-left,.p-selectbutton .p-button .p-button-icon-right{
    color:#708da9
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
    background:#f6f9fc;
    border-color:#d3dbe3;
    color:#043d75
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right{
    color:#708da9
}
.p-selectbutton .p-button.p-highlight{
    background:#7254f3;
    border-color:#7254f3;
    color:#fff
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,.p-selectbutton .p-button.p-highlight .p-button-icon-right{
    color:#fff
}
.p-selectbutton .p-button.p-highlight:hover{
    background:#6545f2;
    border-color:#6545f2;
    color:#fff
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right{
    color:#fff
}
p-selectbutton.ng-dirty.ng-invalid>.p-selectbutton>.p-button{
    border-color:#ff6767
}
.p-slider{
    background:#dfe7ef;
    border:0 none;
    border-radius:6px
}
.p-slider.p-slider-horizontal{
    height:.286rem
}
.p-slider.p-slider-horizontal .p-slider-handle{
    margin-top:-0.5715rem;
    margin-left:-0.5715rem
}
.p-slider.p-slider-vertical{
    width:.286rem
}
.p-slider.p-slider-vertical .p-slider-handle{
    margin-left:-0.5715rem;
    margin-bottom:-0.5715rem
}
.p-slider .p-slider-handle{
    height:1.143rem;
    width:1.143rem;
    background:#fff;
    border:2px solid #7254f3;
    border-radius:50%;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-slider .p-slider-handle:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-slider .p-slider-range{
    background:#7254f3
}
.p-slider:not(.p-disabled) .p-slider-handle:hover{
    background:#7254f3;
    border-color:#7254f3
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle{
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s,left .2s
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range{
    transition:width .2s
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle{
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s,bottom .2s
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range{
    transition:height .2s
}
.p-togglebutton.p-button{
    background:#fff;
    border:1px solid #d3dbe3;
    color:#043d75;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-togglebutton.p-button .p-button-icon-left,.p-togglebutton.p-button .p-button-icon-right{
    color:#708da9
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover{
    background:#f6f9fc;
    border-color:#d3dbe3;
    color:#043d75
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right{
    color:#708da9
}
.p-togglebutton.p-button.p-highlight{
    background:#7254f3;
    border-color:#7254f3;
    color:#fff
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,.p-togglebutton.p-button.p-highlight .p-button-icon-right{
    color:#fff
}
.p-togglebutton.p-button.p-highlight:hover{
    background:#6545f2;
    border-color:#6545f2;
    color:#fff
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right{
    color:#fff
}
p-togglebutton.ng-dirty.ng-invalid>.p-togglebutton.p-button{
    border-color:#ff6767
}
.p-treeselect{
    background:#fff;
    border:1px solid #d3dbe3;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.p-treeselect:not(.p-disabled):hover{
    border-color:#7254f3
}
.p-treeselect:not(.p-disabled).p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    border-color:#7254f3
}
.p-treeselect .p-treeselect-label{
    padding:.75rem .75rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-treeselect .p-treeselect-label.p-placeholder{
    color:#708da9
}
.p-treeselect.p-treeselect-chip .p-treeselect-token{
    padding:.375rem .75rem;
    margin-right:.5rem;
    background:#dfe7ef;
    color:#043d75;
    border-radius:16px
}
.p-treeselect .p-treeselect-trigger{
    background:transparent;
    color:#708da9;
    width:3rem;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
p-treeselect.ng-invalid.ng-dirty>.p-treeselect{
    border-color:#ff6767
}
.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label{
    padding:.375rem .75rem
}
.p-treeselect-panel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-treeselect-panel .p-treeselect-header{
    padding:.75rem 1.25rem;
    border-bottom:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    margin:0;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container{
    margin-right:.5rem
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter{
    padding-right:1.75rem
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter{
    padding-right:3.5rem
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon{
    right:2.5rem
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree{
    border:0 none
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75;
    background:transparent
}
.p-input-filled .p-treeselect{
    background:#f6f9fc
}
.p-input-filled .p-treeselect:not(.p-disabled):hover{
    background-color:#f6f9fc
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus{
    background-color:#fff
}
p-treeselect.p-treeselect-clearable .p-treeselect-label-container{
    padding-right:1.75rem
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon{
    color:#708da9;
    right:3rem
}
.p-button{
    color:#fff;
    background:#7254f3;
    border:1px solid #7254f3;
    padding:.75rem 1.25rem;
    font-size:1rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.p-button:enabled:hover{
    background:#6545f2;
    color:#fff;
    border-color:#6545f2
}
.p-button:enabled:active{
    background:#5935f1;
    color:#fff;
    border-color:#5935f1
}
.p-button.p-button-outlined{
    background-color:transparent;
    color:#7254f3;
    border:1px solid
}
.p-button.p-button-outlined:enabled:hover{
    background:rgba(114,84,243,.04);
    color:#7254f3;
    border:1px solid
}
.p-button.p-button-outlined:enabled:active{
    background:rgba(114,84,243,.16);
    color:#7254f3;
    border:1px solid
}
.p-button.p-button-outlined.p-button-plain{
    color:#708da9;
    border-color:#708da9
}
.p-button.p-button-outlined.p-button-plain:enabled:hover{
    background:#f6f9fc;
    color:#708da9
}
.p-button.p-button-outlined.p-button-plain:enabled:active{
    background:#dfe7ef;
    color:#708da9
}
.p-button.p-button-text{
    background-color:transparent;
    color:#7254f3;
    border-color:transparent
}
.p-button.p-button-text:enabled:hover{
    background:rgba(114,84,243,.04);
    color:#7254f3;
    border-color:transparent
}
.p-button.p-button-text:enabled:active{
    background:rgba(114,84,243,.16);
    color:#7254f3;
    border-color:transparent
}
.p-button.p-button-text.p-button-plain{
    color:#708da9
}
.p-button.p-button-text.p-button-plain:enabled:hover{
    background:#f6f9fc;
    color:#708da9
}
.p-button.p-button-text.p-button-plain:enabled:active{
    background:#dfe7ef;
    color:#708da9
}
.p-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-button .p-button-label{
    transition-duration:.2s
}
.p-button .p-button-icon-left{
    margin-right:.5rem
}
.p-button .p-button-icon-right{
    margin-left:.5rem
}
.p-button .p-button-icon-bottom{
    margin-top:.5rem
}
.p-button .p-button-icon-top{
    margin-bottom:.5rem
}
.p-button .p-badge{
    margin-left:.5rem;
    min-width:1rem;
    height:1rem;
    line-height:1rem;
    color:#7254f3;
    background-color:#fff
}
.p-button.p-button-raised{
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)
}
.p-button.p-button-rounded{
    border-radius:2rem
}
.p-button.p-button-icon-only{
    width:3rem;
    padding:.75rem 0
}
.p-button.p-button-icon-only .p-button-icon-left,.p-button.p-button-icon-only .p-button-icon-right{
    margin:0
}
.p-button.p-button-icon-only.p-button-rounded{
    border-radius:50%;
    height:3rem
}
.p-button.p-button-sm{
    font-size:.875rem;
    padding:.65625rem 1.09375rem
}
.p-button.p-button-sm .p-button-icon{
    font-size:.875rem
}
.p-button.p-button-lg{
    font-size:1.25rem;
    padding:.9375rem 1.5625rem
}
.p-button.p-button-lg .p-button-icon{
    font-size:1.25rem
}
.p-button.p-button-loading-label-only .p-button-label{
    margin-left:.5rem
}
.p-button.p-button-loading-label-only .p-button-loading-icon{
    margin-right:0
}
.p-fluid .p-button{
    width:100%
}
.p-fluid .p-button-icon-only{
    width:3rem
}
.p-fluid .p-buttonset{
    display:flex
}
.p-fluid .p-buttonset .p-button{
    flex:1
}
.p-button.p-button-secondary,.p-buttonset.p-button-secondary>.p-button,.p-splitbutton.p-button-secondary>.p-button{
    color:#fff;
    background:#a1c30d;
    border:1px solid #a1c30d
}
.p-button.p-button-secondary:enabled:hover,.p-buttonset.p-button-secondary>.p-button:enabled:hover,.p-splitbutton.p-button-secondary>.p-button:enabled:hover{
    background:#91b00c;
    color:#fff;
    border-color:#91b00c
}
.p-button.p-button-secondary:enabled:focus,.p-buttonset.p-button-secondary>.p-button:enabled:focus,.p-splitbutton.p-button-secondary>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #e4f78e
}
.p-button.p-button-secondary:enabled:active,.p-buttonset.p-button-secondary>.p-button:enabled:active,.p-splitbutton.p-button-secondary>.p-button:enabled:active{
    background:#819c0a;
    color:#fff;
    border-color:#819c0a
}
.p-button.p-button-secondary.p-button-outlined,.p-buttonset.p-button-secondary>.p-button.p-button-outlined,.p-splitbutton.p-button-secondary>.p-button.p-button-outlined{
    background-color:transparent;
    color:#a1c30d;
    border:1px solid
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:hover{
    background:rgba(161,195,13,.04);
    color:#a1c30d;
    border:1px solid
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:active{
    background:rgba(161,195,13,.16);
    color:#a1c30d;
    border:1px solid
}
.p-button.p-button-secondary.p-button-text,.p-buttonset.p-button-secondary>.p-button.p-button-text,.p-splitbutton.p-button-secondary>.p-button.p-button-text{
    background-color:transparent;
    color:#a1c30d;
    border-color:transparent
}
.p-button.p-button-secondary.p-button-text:enabled:hover,.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:hover{
    background:rgba(161,195,13,.04);
    border-color:transparent;
    color:#a1c30d
}
.p-button.p-button-secondary.p-button-text:enabled:active,.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:active{
    background:rgba(161,195,13,.16);
    border-color:transparent;
    color:#a1c30d
}
.p-button.p-button-info,.p-buttonset.p-button-info>.p-button,.p-splitbutton.p-button-info>.p-button{
    color:#fff;
    background:#5486f3;
    border:1px solid #5486f3
}
.p-button.p-button-info:enabled:hover,.p-buttonset.p-button-info>.p-button:enabled:hover,.p-splitbutton.p-button-info>.p-button:enabled:hover{
    background:#3570f1;
    color:#fff;
    border-color:#3570f1
}
.p-button.p-button-info:enabled:focus,.p-buttonset.p-button-info>.p-button:enabled:focus,.p-splitbutton.p-button-info>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #bbcffa
}
.p-button.p-button-info:enabled:active,.p-buttonset.p-button-info>.p-button:enabled:active,.p-splitbutton.p-button-info>.p-button:enabled:active{
    background:#175bef;
    color:#fff;
    border-color:#175bef
}
.p-button.p-button-info.p-button-outlined,.p-buttonset.p-button-info>.p-button.p-button-outlined,.p-splitbutton.p-button-info>.p-button.p-button-outlined{
    background-color:transparent;
    color:#5486f3;
    border:1px solid
}
.p-button.p-button-info.p-button-outlined:enabled:hover,.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:hover{
    background:rgba(84,134,243,.04);
    color:#5486f3;
    border:1px solid
}
.p-button.p-button-info.p-button-outlined:enabled:active,.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active{
    background:rgba(84,134,243,.16);
    color:#5486f3;
    border:1px solid
}
.p-button.p-button-info.p-button-text,.p-buttonset.p-button-info>.p-button.p-button-text,.p-splitbutton.p-button-info>.p-button.p-button-text{
    background-color:transparent;
    color:#5486f3;
    border-color:transparent
}
.p-button.p-button-info.p-button-text:enabled:hover,.p-buttonset.p-button-info>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:hover{
    background:rgba(84,134,243,.04);
    border-color:transparent;
    color:#5486f3
}
.p-button.p-button-info.p-button-text:enabled:active,.p-buttonset.p-button-info>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:active{
    background:rgba(84,134,243,.16);
    border-color:transparent;
    color:#5486f3
}
.p-button.p-button-success,.p-buttonset.p-button-success>.p-button,.p-splitbutton.p-button-success>.p-button{
    color:#fff;
    background:#29c76f;
    border:1px solid #29c76f
}
.p-button.p-button-success:enabled:hover,.p-buttonset.p-button-success>.p-button:enabled:hover,.p-splitbutton.p-button-success>.p-button:enabled:hover{
    background:#25b364;
    color:#fff;
    border-color:#25b364
}
.p-button.p-button-success:enabled:focus,.p-buttonset.p-button-success>.p-button:enabled:focus,.p-splitbutton.p-button-success>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #a5edc5
}
.p-button.p-button-success:enabled:active,.p-buttonset.p-button-success>.p-button:enabled:active,.p-splitbutton.p-button-success>.p-button:enabled:active{
    background:#219f59;
    color:#fff;
    border-color:#219f59
}
.p-button.p-button-success.p-button-outlined,.p-buttonset.p-button-success>.p-button.p-button-outlined,.p-splitbutton.p-button-success>.p-button.p-button-outlined{
    background-color:transparent;
    color:#29c76f;
    border:1px solid
}
.p-button.p-button-success.p-button-outlined:enabled:hover,.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:hover{
    background:rgba(41,199,111,.04);
    color:#29c76f;
    border:1px solid
}
.p-button.p-button-success.p-button-outlined:enabled:active,.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:active{
    background:rgba(41,199,111,.16);
    color:#29c76f;
    border:1px solid
}
.p-button.p-button-success.p-button-text,.p-buttonset.p-button-success>.p-button.p-button-text,.p-splitbutton.p-button-success>.p-button.p-button-text{
    background-color:transparent;
    color:#29c76f;
    border-color:transparent
}
.p-button.p-button-success.p-button-text:enabled:hover,.p-buttonset.p-button-success>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:hover{
    background:rgba(41,199,111,.04);
    border-color:transparent;
    color:#29c76f
}
.p-button.p-button-success.p-button-text:enabled:active,.p-buttonset.p-button-success>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:active{
    background:rgba(41,199,111,.16);
    border-color:transparent;
    color:#29c76f
}
.p-button.p-button-warning,.p-buttonset.p-button-warning>.p-button,.p-splitbutton.p-button-warning>.p-button{
    color:#212529;
    background:#ff9f42;
    border:1px solid #ff9f42
}
.p-button.p-button-warning:enabled:hover,.p-buttonset.p-button-warning>.p-button:enabled:hover,.p-splitbutton.p-button-warning>.p-button:enabled:hover{
    background:#ff8f22;
    color:#212529;
    border-color:#ff8f22
}
.p-button.p-button-warning:enabled:focus,.p-buttonset.p-button-warning>.p-button:enabled:focus,.p-splitbutton.p-button-warning>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #ffd9b3
}
.p-button.p-button-warning:enabled:active,.p-buttonset.p-button-warning>.p-button:enabled:active,.p-splitbutton.p-button-warning>.p-button:enabled:active{
    background:#ff7e02;
    color:#212529;
    border-color:#ff7e02
}
.p-button.p-button-warning.p-button-outlined,.p-buttonset.p-button-warning>.p-button.p-button-outlined,.p-splitbutton.p-button-warning>.p-button.p-button-outlined{
    background-color:transparent;
    color:#ff9f42;
    border:1px solid
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:hover{
    background:rgba(255,159,66,.04);
    color:#ff9f42;
    border:1px solid
}
.p-button.p-button-warning.p-button-outlined:enabled:active,.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:active{
    background:rgba(255,159,66,.16);
    color:#ff9f42;
    border:1px solid
}
.p-button.p-button-warning.p-button-text,.p-buttonset.p-button-warning>.p-button.p-button-text,.p-splitbutton.p-button-warning>.p-button.p-button-text{
    background-color:transparent;
    color:#ff9f42;
    border-color:transparent
}
.p-button.p-button-warning.p-button-text:enabled:hover,.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:hover{
    background:rgba(255,159,66,.04);
    border-color:transparent;
    color:#ff9f42
}
.p-button.p-button-warning.p-button-text:enabled:active,.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:active{
    background:rgba(255,159,66,.16);
    border-color:transparent;
    color:#ff9f42
}
.p-button.p-button-help,.p-buttonset.p-button-help>.p-button,.p-splitbutton.p-button-help>.p-button{
    color:#fff;
    background:#3ec9d6;
    border:1px solid #3ec9d6
}
.p-button.p-button-help:enabled:hover,.p-buttonset.p-button-help>.p-button:enabled:hover,.p-splitbutton.p-button-help>.p-button:enabled:hover{
    background:#2cbfcd;
    color:#fff;
    border-color:#2cbfcd
}
.p-button.p-button-help:enabled:focus,.p-buttonset.p-button-help>.p-button:enabled:focus,.p-splitbutton.p-button-help>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #b2e9ef
}
.p-button.p-button-help:enabled:active,.p-buttonset.p-button-help>.p-button:enabled:active,.p-splitbutton.p-button-help>.p-button:enabled:active{
    background:#27aab6;
    color:#fff;
    border-color:#27aab6
}
.p-button.p-button-help.p-button-outlined,.p-buttonset.p-button-help>.p-button.p-button-outlined,.p-splitbutton.p-button-help>.p-button.p-button-outlined{
    background-color:transparent;
    color:#3ec9d6;
    border:1px solid
}
.p-button.p-button-help.p-button-outlined:enabled:hover,.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:hover{
    background:rgba(62,201,214,.04);
    color:#3ec9d6;
    border:1px solid
}
.p-button.p-button-help.p-button-outlined:enabled:active,.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:active{
    background:rgba(62,201,214,.16);
    color:#3ec9d6;
    border:1px solid
}
.p-button.p-button-help.p-button-text,.p-buttonset.p-button-help>.p-button.p-button-text,.p-splitbutton.p-button-help>.p-button.p-button-text{
    background-color:transparent;
    color:#3ec9d6;
    border-color:transparent
}
.p-button.p-button-help.p-button-text:enabled:hover,.p-buttonset.p-button-help>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:hover{
    background:rgba(62,201,214,.04);
    border-color:transparent;
    color:#3ec9d6
}
.p-button.p-button-help.p-button-text:enabled:active,.p-buttonset.p-button-help>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:active{
    background:rgba(62,201,214,.16);
    border-color:transparent;
    color:#3ec9d6
}
.p-button.p-button-danger,.p-buttonset.p-button-danger>.p-button,.p-splitbutton.p-button-danger>.p-button{
    color:#fff;
    background:#ea5455;
    border:1px solid #ea5455
}
.p-button.p-button-danger:enabled:hover,.p-buttonset.p-button-danger>.p-button:enabled:hover,.p-splitbutton.p-button-danger>.p-button:enabled:hover{
    background:#e73839;
    color:#fff;
    border-color:#e73839
}
.p-button.p-button-danger:enabled:focus,.p-buttonset.p-button-danger>.p-button:enabled:focus,.p-splitbutton.p-button-danger>.p-button:enabled:focus{
    box-shadow:0 0 0 .2rem #f7bbbb
}
.p-button.p-button-danger:enabled:active,.p-buttonset.p-button-danger>.p-button:enabled:active,.p-splitbutton.p-button-danger>.p-button:enabled:active{
    background:#e31c1d;
    color:#fff;
    border-color:#e31c1d
}
.p-button.p-button-danger.p-button-outlined,.p-buttonset.p-button-danger>.p-button.p-button-outlined,.p-splitbutton.p-button-danger>.p-button.p-button-outlined{
    background-color:transparent;
    color:#ea5455;
    border:1px solid
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:hover,.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:hover{
    background:rgba(234,84,85,.04);
    color:#ea5455;
    border:1px solid
}
.p-button.p-button-danger.p-button-outlined:enabled:active,.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:active,.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:active{
    background:rgba(234,84,85,.16);
    color:#ea5455;
    border:1px solid
}
.p-button.p-button-danger.p-button-text,.p-buttonset.p-button-danger>.p-button.p-button-text,.p-splitbutton.p-button-danger>.p-button.p-button-text{
    background-color:transparent;
    color:#ea5455;
    border-color:transparent
}
.p-button.p-button-danger.p-button-text:enabled:hover,.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:hover,.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:hover{
    background:rgba(234,84,85,.04);
    border-color:transparent;
    color:#ea5455
}
.p-button.p-button-danger.p-button-text:enabled:active,.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:active,.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:active{
    background:rgba(234,84,85,.16);
    border-color:transparent;
    color:#ea5455
}
.p-button.p-button-link{
    color:#5935f1;
    background:transparent;
    border:transparent
}
.p-button.p-button-link:enabled:hover{
    background:transparent;
    color:#5935f1;
    border-color:transparent
}
.p-button.p-button-link:enabled:hover .p-button-label{
    text-decoration:underline
}
.p-button.p-button-link:enabled:focus{
    background:transparent;
    box-shadow:0 0 0 .2rem #c7bbfa;
    border-color:transparent
}
.p-button.p-button-link:enabled:active{
    background:transparent;
    color:#5935f1;
    border-color:transparent
}
.p-speeddial-button.p-button.p-button-icon-only{
    width:4rem;
    height:4rem
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon{
    font-size:1.3rem
}
.p-speeddial-button.p-button.p-button-icon-only .p-icon{
    width:1.3rem;
    height:1.3rem
}
.p-speeddial-action{
    width:3rem;
    height:3rem;
    background:#043d75;
    color:#fff
}
.p-speeddial-action:hover{
    background:#022354;
    color:#fff
}
.p-speeddial-direction-up .p-speeddial-item{
    margin:.25rem 0
}
.p-speeddial-direction-up .p-speeddial-item:first-child{
    margin-bottom:.5rem
}
.p-speeddial-direction-down .p-speeddial-item{
    margin:.25rem 0
}
.p-speeddial-direction-down .p-speeddial-item:first-child{
    margin-top:.5rem
}
.p-speeddial-direction-left .p-speeddial-item{
    margin:0 .25rem
}
.p-speeddial-direction-left .p-speeddial-item:first-child{
    margin-right:.5rem
}
.p-speeddial-direction-right .p-speeddial-item{
    margin:0 .25rem
}
.p-speeddial-direction-right .p-speeddial-item:first-child{
    margin-left:.5rem
}
.p-speeddial-circle .p-speeddial-item,.p-speeddial-semi-circle .p-speeddial-item,.p-speeddial-quarter-circle .p-speeddial-item{
    margin:0
}
.p-speeddial-circle .p-speeddial-item:first-child,.p-speeddial-circle .p-speeddial-item:last-child,.p-speeddial-semi-circle .p-speeddial-item:first-child,.p-speeddial-semi-circle .p-speeddial-item:last-child,.p-speeddial-quarter-circle .p-speeddial-item:first-child,.p-speeddial-quarter-circle .p-speeddial-item:last-child{
    margin:0
}
.p-speeddial-mask{
    background-color:rgba(0,0,0,.4)
}
.p-splitbutton{
    border-radius:6px
}
.p-splitbutton.p-button-outlined>.p-button{
    background-color:transparent;
    color:#7254f3;
    border:1px solid
}
.p-splitbutton.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(114,84,243,.04);
    color:#7254f3
}
.p-splitbutton.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(114,84,243,.16);
    color:#7254f3
}
.p-splitbutton.p-button-outlined.p-button-plain>.p-button{
    color:#708da9;
    border-color:#708da9
}
.p-splitbutton.p-button-outlined.p-button-plain>.p-button:enabled:hover,.p-splitbutton.p-button-outlined.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:#f6f9fc;
    color:#708da9
}
.p-splitbutton.p-button-outlined.p-button-plain>.p-button:enabled:active,.p-splitbutton.p-button-outlined.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):active{
    background:#dfe7ef;
    color:#708da9
}
.p-splitbutton.p-button-text>.p-button{
    background-color:transparent;
    color:#7254f3;
    border-color:transparent
}
.p-splitbutton.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(114,84,243,.04);
    color:#7254f3;
    border-color:transparent
}
.p-splitbutton.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(114,84,243,.16);
    color:#7254f3;
    border-color:transparent
}
.p-splitbutton.p-button-text.p-button-plain>.p-button{
    color:#708da9
}
.p-splitbutton.p-button-text.p-button-plain>.p-button:enabled:hover,.p-splitbutton.p-button-text.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:#f6f9fc;
    color:#708da9
}
.p-splitbutton.p-button-text.p-button-plain>.p-button:enabled:active,.p-splitbutton.p-button-text.p-button-plain>.p-button:not(button):not(a):not(.p-disabled):active{
    background:#dfe7ef;
    color:#708da9
}
.p-splitbutton.p-button-raised{
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)
}
.p-splitbutton.p-button-rounded{
    border-radius:2rem
}
.p-splitbutton.p-button-rounded>.p-button{
    border-radius:2rem
}
.p-splitbutton.p-button-sm>.p-button{
    font-size:.875rem;
    padding:.65625rem 1.09375rem
}
.p-splitbutton.p-button-sm>.p-button .p-button-icon{
    font-size:.875rem
}
.p-splitbutton.p-button-lg>.p-button{
    font-size:1.25rem;
    padding:.9375rem 1.5625rem
}
.p-splitbutton.p-button-lg>.p-button .p-button-icon{
    font-size:1.25rem
}
.p-splitbutton.p-button-secondary.p-button-outlined>.p-button{
    background-color:transparent;
    color:#a1c30d;
    border:1px solid
}
.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(161,195,13,.04);
    color:#a1c30d
}
.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-secondary.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(161,195,13,.16);
    color:#a1c30d
}
.p-splitbutton.p-button-secondary.p-button-text>.p-button{
    background-color:transparent;
    color:#a1c30d;
    border-color:transparent
}
.p-splitbutton.p-button-secondary.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-secondary.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(161,195,13,.04);
    border-color:transparent;
    color:#a1c30d
}
.p-splitbutton.p-button-secondary.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-secondary.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(161,195,13,.16);
    border-color:transparent;
    color:#a1c30d
}
.p-splitbutton.p-button-info.p-button-outlined>.p-button{
    background-color:transparent;
    color:#5486f3;
    border:1px solid
}
.p-splitbutton.p-button-info.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-info.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(84,134,243,.04);
    color:#5486f3
}
.p-splitbutton.p-button-info.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-info.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(84,134,243,.16);
    color:#5486f3
}
.p-splitbutton.p-button-info.p-button-text>.p-button{
    background-color:transparent;
    color:#5486f3;
    border-color:transparent
}
.p-splitbutton.p-button-info.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-info.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(84,134,243,.04);
    border-color:transparent;
    color:#5486f3
}
.p-splitbutton.p-button-info.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-info.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(84,134,243,.16);
    border-color:transparent;
    color:#5486f3
}
.p-splitbutton.p-button-success.p-button-outlined>.p-button{
    background-color:transparent;
    color:#29c76f;
    border:1px solid
}
.p-splitbutton.p-button-success.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-success.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(41,199,111,.04);
    color:#29c76f
}
.p-splitbutton.p-button-success.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-success.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(41,199,111,.16);
    color:#29c76f
}
.p-splitbutton.p-button-success.p-button-text>.p-button{
    background-color:transparent;
    color:#29c76f;
    border-color:transparent
}
.p-splitbutton.p-button-success.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-success.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(41,199,111,.04);
    border-color:transparent;
    color:#29c76f
}
.p-splitbutton.p-button-success.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-success.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(41,199,111,.16);
    border-color:transparent;
    color:#29c76f
}
.p-splitbutton.p-button-warning.p-button-outlined>.p-button{
    background-color:transparent;
    color:#ff9f42;
    border:1px solid
}
.p-splitbutton.p-button-warning.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-warning.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(255,159,66,.04);
    color:#ff9f42
}
.p-splitbutton.p-button-warning.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-warning.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(255,159,66,.16);
    color:#ff9f42
}
.p-splitbutton.p-button-warning.p-button-text>.p-button{
    background-color:transparent;
    color:#ff9f42;
    border-color:transparent
}
.p-splitbutton.p-button-warning.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-warning.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(255,159,66,.04);
    border-color:transparent;
    color:#ff9f42
}
.p-splitbutton.p-button-warning.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-warning.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(255,159,66,.16);
    border-color:transparent;
    color:#ff9f42
}
.p-splitbutton.p-button-help.p-button-outlined>.p-button{
    background-color:transparent;
    color:#3ec9d6;
    border:1px solid
}
.p-splitbutton.p-button-help.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-help.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(62,201,214,.04);
    color:#3ec9d6
}
.p-splitbutton.p-button-help.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-help.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(62,201,214,.16);
    color:#3ec9d6
}
.p-splitbutton.p-button-help.p-button-text>.p-button{
    background-color:transparent;
    color:#3ec9d6;
    border-color:transparent
}
.p-splitbutton.p-button-help.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-help.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(62,201,214,.04);
    border-color:transparent;
    color:#3ec9d6
}
.p-splitbutton.p-button-help.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-help.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(62,201,214,.16);
    border-color:transparent;
    color:#3ec9d6
}
.p-splitbutton.p-button-danger.p-button-outlined>.p-button{
    background-color:transparent;
    color:#ea5455;
    border:1px solid
}
.p-splitbutton.p-button-danger.p-button-outlined>.p-button:enabled:hover,.p-splitbutton.p-button-danger.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(234,84,85,.04);
    color:#ea5455
}
.p-splitbutton.p-button-danger.p-button-outlined>.p-button:enabled:active,.p-splitbutton.p-button-danger.p-button-outlined>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(234,84,85,.16);
    color:#ea5455
}
.p-splitbutton.p-button-danger.p-button-text>.p-button{
    background-color:transparent;
    color:#ea5455;
    border-color:transparent
}
.p-splitbutton.p-button-danger.p-button-text>.p-button:enabled:hover,.p-splitbutton.p-button-danger.p-button-text>.p-button:not(button):not(a):not(.p-disabled):hover{
    background:rgba(234,84,85,.04);
    border-color:transparent;
    color:#ea5455
}
.p-splitbutton.p-button-danger.p-button-text>.p-button:enabled:active,.p-splitbutton.p-button-danger.p-button-text>.p-button:not(button):not(a):not(.p-disabled):active{
    background:rgba(234,84,85,.16);
    border-color:transparent;
    color:#ea5455
}
.p-carousel .p-carousel-content .p-carousel-prev,.p-carousel .p-carousel-content .p-carousel-next{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s;
    margin:.5rem
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,.p-carousel .p-carousel-content .p-carousel-next:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,.p-carousel .p-carousel-content .p-carousel-next:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-carousel .p-carousel-indicators{
    padding:1rem
}
.p-carousel .p-carousel-indicators .p-carousel-indicator{
    margin-right:.5rem;
    margin-bottom:.5rem
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button{
    background-color:#d3dbe3;
    width:2rem;
    height:.5rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:0
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover{
    background:#c6ccd7
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background:#e2dcfc;
    color:#7254f3
}
.p-datatable .p-paginator-top{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-datatable .p-paginator-bottom{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-datatable .p-datatable-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:1px 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-datatable .p-datatable-footer{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-datatable .p-datatable-thead>tr>th{
    text-align:left;
    padding:1rem 1rem;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    font-weight:700;
    color:#708da9;
    background:#eff3f8;
    transition:box-shadow .2s
}
.p-datatable .p-datatable-tfoot>tr>td{
    text-align:left;
    padding:1rem 1rem;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    font-weight:700;
    color:#708da9;
    background:#eff3f8
}
.p-datatable .p-sortable-column .p-sortable-column-icon{
    color:#708da9;
    margin-left:.5rem
}
.p-datatable .p-sortable-column .p-sortable-column-badge{
    border-radius:50%;
    height:1.143rem;
    min-width:1.143rem;
    line-height:1.143rem;
    color:#7254f3;
    background:#e2dcfc;
    margin-left:.5rem
}
.p-datatable .p-sortable-column:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#708da9
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon{
    color:#708da9
}
.p-datatable .p-sortable-column.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
    color:#7254f3
}
.p-datatable .p-sortable-column.p-highlight:hover{
    background:#e2dcfc;
    color:#7254f3
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon{
    color:#7254f3
}
.p-datatable .p-sortable-column:focus{
    box-shadow:inset 0 0 0 .15rem #c7bbfa;
    outline:0 none
}
.p-datatable .p-datatable-tbody>tr{
    background:#fff;
    color:#043d75;
    transition:box-shadow .2s
}
.p-datatable .p-datatable-tbody>tr>td{
    text-align:left;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem
}
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save{
    margin-right:.5rem
}
.p-datatable .p-datatable-tbody>tr:focus{
    outline:.15rem solid #c7bbfa;
    outline-offset:.15rem
}
.p-datatable .p-datatable-tbody>tr.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td{
    box-shadow:inset 0 2px 0 0 #e2dcfc
}
.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td{
    box-shadow:inset 0 -2px 0 0 #e2dcfc
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-datatable .p-column-resizer-helper{
    background:#7254f3
}
.p-datatable .p-datatable-scrollable-header,.p-datatable .p-datatable-scrollable-footer{
    background:#eff3f8
}
.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot,.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-thead,.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-tfoot{
    background-color:#eff3f8
}
.p-datatable .p-datatable-loading-icon{
    font-size:2rem
}
.p-datatable.p-datatable-gridlines .p-datatable-header{
    border-width:1px 1px 0 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-footer{
    border-width:0 1px 1px 1px
}
.p-datatable.p-datatable-gridlines .p-paginator-top{
    border-width:0 1px 0 1px
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom{
    border-width:0 1px 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th{
    border-width:1px 0 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child{
    border-width:1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td{
    border-width:1px 0 0 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child{
    border-width:1px 1px 0 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td{
    border-width:1px 0 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td:last-child{
    border-width:1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td{
    border-width:1px 0 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td:last-child{
    border-width:1px 1px 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td{
    border-width:0 0 1px 1px
}
.p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td:last-child{
    border-width:0 1px 1px 1px
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td{
    border-width:0 0 1px 1px
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child{
    border-width:0 1px 1px 1px
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td{
    border-width:0 0 0 1px
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td:last-child{
    border-width:0 1px 0 1px
}
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even){
    background:#fcfcfc
}
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler{
    color:#7254f3
}
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover{
    color:#7254f3
}
.p-datatable.p-datatable-sm .p-datatable-header{
    padding:.5rem .5rem
}
.p-datatable.p-datatable-sm .p-datatable-thead>tr>th{
    padding:.5rem .5rem
}
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td{
    padding:.5rem .5rem
}
.p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td{
    padding:.5rem .5rem
}
.p-datatable.p-datatable-sm .p-datatable-footer{
    padding:.5rem .5rem
}
.p-datatable.p-datatable-lg .p-datatable-header{
    padding:1.25rem 1.25rem
}
.p-datatable.p-datatable-lg .p-datatable-thead>tr>th{
    padding:1.25rem 1.25rem
}
.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td{
    padding:1.25rem 1.25rem
}
.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td{
    padding:1.25rem 1.25rem
}
.p-datatable.p-datatable-lg .p-datatable-footer{
    padding:1.25rem 1.25rem
}
.p-dataview .p-paginator-top{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-dataview .p-paginator-bottom{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-dataview .p-dataview-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:1px 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-dataview .p-dataview-content{
    background:#fff;
    color:#043d75;
    border:0 none;
    padding:0
}
.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div{
    border:solid #dfe7ef;
    border-width:0 0 1px 0
}
.p-dataview .p-dataview-footer{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem;
    font-weight:700;
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px
}
.p-dataview .p-dataview-loading-icon{
    font-size:2rem
}
.p-dataview .p-dataview-emptymessage{
    padding:1.25rem
}
.p-column-filter-row .p-column-filter-menu-button,.p-column-filter-row .p-column-filter-clear-button{
    margin-left:.5rem
}
.p-column-filter-menu-button{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-column-filter-menu-button:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,.p-column-filter-menu-button.p-column-filter-menu-button-open:hover{
    background:#f6f9fc;
    color:#043d75
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,.p-column-filter-menu-button.p-column-filter-menu-button-active:hover{
    background:#e2dcfc;
    color:#7254f3
}
.p-column-filter-menu-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-column-filter-clear-button{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-column-filter-clear-button:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-column-filter-clear-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-column-filter-overlay{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    min-width:12.5rem
}
.p-column-filter-overlay .p-column-filter-row-items{
    padding:.75rem 0
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item{
    margin:0;
    padding:.75rem 1.25rem;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s;
    border-radius:0
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover{
    color:#043d75;
    background:#f6f9fc
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-column-filter-overlay-menu .p-column-filter-operator{
    padding:.75rem 1.25rem;
    border-bottom:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    margin:0;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-column-filter-overlay-menu .p-column-filter-constraint{
    padding:1.25rem;
    border-bottom:1px solid #dfe7ef
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown{
    margin-bottom:.5rem
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button{
    margin-top:.5rem
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child{
    border-bottom:0 none
}
.p-column-filter-overlay-menu .p-column-filter-add-rule{
    padding:.75rem 1.25rem
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar{
    padding:1.25rem
}
.fc.fc-unthemed .fc-view-container th{
    background:#eff3f8;
    border:1px solid #dfe7ef;
    color:#708da9
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content{
    background:#fff;
    border:1px solid #dfe7ef;
    color:#043d75
}
.fc.fc-unthemed .fc-view-container td.fc-head-container{
    border:1px solid #dfe7ef
}
.fc.fc-unthemed .fc-view-container .fc-row{
    border-right:1px solid #dfe7ef
}
.fc.fc-unthemed .fc-view-container .fc-event{
    background:#6545f2;
    border:1px solid #6545f2;
    color:#fff
}
.fc.fc-unthemed .fc-view-container .fc-divider{
    background:#eff3f8;
    border:1px solid #dfe7ef
}
.fc.fc-unthemed .fc-toolbar .fc-button{
    color:#fff;
    background:#7254f3;
    border:1px solid #7254f3;
    font-size:1rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px;
    display:flex;
    align-items:center
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover{
    background:#6545f2;
    color:#fff;
    border-color:#6545f2
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active{
    background:#5935f1;
    color:#fff;
    border-color:#5935f1
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left{
    font-family:"PrimeIcons" !important;
    text-indent:0;
    font-size:1rem
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before{
    content:""
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right{
    font-family:"PrimeIcons" !important;
    text-indent:0;
    font-size:1rem
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before{
    content:""
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button{
    background:#fff;
    border:1px solid #d3dbe3;
    color:#043d75;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover{
    background:#f6f9fc;
    border-color:#d3dbe3;
    color:#043d75
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active{
    background:#7254f3;
    border-color:#7254f3;
    color:#fff
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover{
    background:#6545f2;
    border-color:#6545f2;
    color:#fff
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    z-index:1
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button{
    border-radius:0
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid{
    border-color:#dfe7ef
}
.fc.fc-theme-standard .fc-view-harness th{
    background:#eff3f8;
    border-color:#dfe7ef;
    color:#708da9
}
.fc.fc-theme-standard .fc-view-harness td{
    color:#043d75;
    border-color:#dfe7ef
}
.fc.fc-theme-standard .fc-view-harness .fc-view{
    background:#fff
}
.fc.fc-theme-standard .fc-view-harness .fc-popover{
    background:none;
    border:0 none
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header{
    border:1px solid #dfe7ef;
    padding:1.25rem;
    background:#eff3f8;
    color:#708da9
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close{
    opacity:1;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    font-family:"PrimeIcons" !important;
    font-size:1rem;
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before{
    content:""
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    border-top:0 none
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event{
    color:#fff;
    background:#6545f2;
    border-color:#6545f2
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main{
    color:#fff
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot{
    background:#6545f2;
    border-color:#6545f2
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover{
    background:#f6f9fc;
    color:#043d75
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded{
    background:#eff3f8
}
.fc.fc-theme-standard .fc-toolbar .fc-button{
    color:#fff;
    background:#7254f3;
    border:1px solid #7254f3;
    font-size:1rem;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius:6px
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover{
    background:#6545f2;
    color:#fff;
    border-color:#6545f2
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active{
    background:#5935f1;
    color:#fff;
    border-color:#5935f1
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled{
    opacity:.6;
    color:#fff;
    background:#7254f3;
    border:1px solid #7254f3
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left{
    font-family:"PrimeIcons" !important;
    text-indent:0;
    font-size:1rem
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before{
    content:""
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right{
    font-family:"PrimeIcons" !important;
    text-indent:0;
    font-size:1rem
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before{
    content:""
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button{
    background:#fff;
    border:1px solid #d3dbe3;
    color:#043d75;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover{
    background:#f6f9fc;
    border-color:#d3dbe3;
    color:#043d75
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active{
    background:#7254f3;
    border-color:#7254f3;
    color:#fff
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover{
    background:#6545f2;
    border-color:#6545f2;
    color:#fff
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa;
    z-index:1
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button{
    border-radius:0
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child{
    border-top-left-radius:6px;
    border-bottom-left-radius:6px
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child{
    border-top-right-radius:6px;
    border-bottom-right-radius:6px
}
.fc.fc-theme-standard a{
    color:#043d75
}
.fc.fc-theme-standard .fc-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-orderlist .p-orderlist-controls{
    padding:1.25rem
}
.p-orderlist .p-orderlist-controls .p-button{
    margin-bottom:.5rem
}
.p-orderlist .p-orderlist-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    padding:1.25rem;
    border-bottom:0 none;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-orderlist .p-orderlist-header .p-orderlist-title{
    font-weight:700
}
.p-orderlist .p-orderlist-filter-container{
    padding:1.25rem;
    background:#fff;
    border:1px solid #dfe7ef;
    border-bottom:0 none
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input{
    padding-right:1.75rem
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-orderlist .p-orderlist-list{
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    padding:.75rem 0;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-orderlist .p-orderlist-list .p-orderlist-item{
    padding:.75rem 1.25rem;
    margin:0;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even){
    background:#fcfcfc
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover{
    background:#f6f9fc
}
.p-orderlist-item.cdk-drag-preview{
    padding:.75rem 1.25rem;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border:0 none;
    color:#043d75;
    background:#fff;
    margin:0
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-organizationchart .p-organizationchart-node-content.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i{
    color:#8167f2
}
.p-organizationchart .p-organizationchart-line-down{
    background:#dfe7ef
}
.p-organizationchart .p-organizationchart-line-left{
    border-right:1px solid #dfe7ef;
    border-color:#dfe7ef
}
.p-organizationchart .p-organizationchart-line-top{
    border-top:1px solid #dfe7ef;
    border-color:#dfe7ef
}
.p-organizationchart .p-organizationchart-node-content{
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    padding:1.25rem
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler{
    background:inherit;
    color:inherit;
    border-radius:50%
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-paginator{
    background:#fff;
    color:#708da9;
    border:solid #f6f9fc;
    border-width:0;
    padding:.5rem 1rem;
    border-radius:6px
}
.p-paginator .p-paginator-first,.p-paginator .p-paginator-prev,.p-paginator .p-paginator-next,.p-paginator .p-paginator-last{
    background-color:transparent;
    border:0 none;
    color:#708da9;
    min-width:3rem;
    height:3rem;
    margin:.143rem;
    transition:box-shadow .2s;
    border-radius:50%
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover{
    background:#f6f9fc;
    border-color:transparent;
    color:#043d75
}
.p-paginator .p-paginator-first{
    border-top-left-radius:50%;
    border-bottom-left-radius:50%
}
.p-paginator .p-paginator-last{
    border-top-right-radius:50%;
    border-bottom-right-radius:50%
}
.p-paginator .p-dropdown{
    margin-left:.5rem;
    margin-right:.5rem;
    height:3rem
}
.p-paginator .p-dropdown .p-dropdown-label{
    padding-right:0
}
.p-paginator .p-paginator-page-input{
    margin-left:.5rem;
    margin-right:.5rem
}
.p-paginator .p-paginator-page-input .p-inputtext{
    max-width:3rem
}
.p-paginator .p-paginator-current{
    background-color:transparent;
    border:0 none;
    color:#708da9;
    min-width:3rem;
    height:3rem;
    margin:.143rem;
    padding:0 .5rem
}
.p-paginator .p-paginator-pages .p-paginator-page{
    background-color:transparent;
    border:0 none;
    color:#708da9;
    min-width:3rem;
    height:3rem;
    margin:.143rem;
    transition:box-shadow .2s;
    border-radius:50%
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    background:#e2dcfc;
    border-color:#e2dcfc;
    color:#7254f3
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover{
    background:#f6f9fc;
    border-color:transparent;
    color:#043d75
}
.p-picklist .p-picklist-buttons{
    padding:1.25rem
}
.p-picklist .p-picklist-buttons .p-button{
    margin-bottom:.5rem
}
.p-picklist .p-picklist-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    padding:1.25rem;
    border-bottom:0 none;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-picklist .p-picklist-header .p-picklist-title{
    font-weight:700
}
.p-picklist .p-picklist-filter-container{
    padding:1.25rem;
    background:#fff;
    border:1px solid #dfe7ef;
    border-bottom:0 none
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input{
    padding-right:1.75rem
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-picklist .p-picklist-list{
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    padding:.75rem 0;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-picklist .p-picklist-list .p-picklist-item{
    padding:.75rem 1.25rem;
    margin:0;
    border:0 none;
    color:#043d75;
    background:transparent;
    transition:box-shadow .2s
}
.p-picklist .p-picklist-list .p-picklist-item:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight{
    color:#7254f3;
    background:#e2dcfc
}
.p-picklist .p-picklist-list .p-picklist-empty-message{
    padding:.75rem 1.25rem;
    color:#043d75
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even){
    background:#fcfcfc
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover{
    background:#f6f9fc
}
.p-picklist-item.cdk-drag-preview{
    padding:.75rem 1.25rem;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border:0 none;
    color:#043d75;
    background:#fff;
    margin:0
}
.p-timeline .p-timeline-event-marker{
    border:2px solid #7254f3;
    border-radius:50%;
    width:1rem;
    height:1rem;
    background-color:#fff
}
.p-timeline .p-timeline-event-connector{
    background-color:#dfe7ef
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,.p-timeline.p-timeline-vertical .p-timeline-event-content{
    padding:0 1rem
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector{
    width:2px
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,.p-timeline.p-timeline-horizontal .p-timeline-event-content{
    padding:1rem 0
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector{
    height:2px
}
.p-tree{
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    padding:1.25rem;
    border-radius:6px
}
.p-tree .p-tree-container .p-treenode{
    padding:.143rem
}
.p-tree .p-tree-container .p-treenode .p-treenode-content{
    border-radius:6px;
    transition:box-shadow .2s;
    padding:.5rem
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler{
    margin-right:.5rem;
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon{
    margin-right:.5rem;
    color:#708da9
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox{
    margin-right:.5rem
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon{
    color:#043d75
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon{
    color:#7254f3
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover{
    color:#7254f3
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover{
    background:#f6f9fc;
    color:#043d75
}
.p-tree .p-tree-filter-container{
    margin-bottom:.5rem
}
.p-tree .p-tree-filter-container .p-tree-filter{
    width:100%;
    padding-right:1.75rem
}
.p-tree .p-tree-filter-container .p-tree-filter-icon{
    right:.75rem;
    color:#708da9
}
.p-tree .p-treenode-children{
    padding:0 0 0 1rem
}
.p-tree .p-tree-loading-icon{
    font-size:2rem
}
.p-tree .p-tree-loading-icon.p-icon{
    width:2rem;
    height:2rem
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active{
    background-color:#9a85f5
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content{
    border-radius:6px;
    border:1px solid #dfe7ef;
    background-color:#fff;
    color:#043d75;
    padding:.5rem;
    transition:box-shadow .2s
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight{
    background-color:#e2dcfc;
    color:#7254f3
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon{
    color:#7254f3
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler{
    margin-right:.5rem
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox{
    margin-right:.5rem
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover{
    background-color:inherit;
    color:inherit
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-treetable .p-paginator-top{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-treetable .p-paginator-bottom{
    border-width:0 0 1px 0;
    border-radius:0
}
.p-treetable .p-treetable-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:1px 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-treetable .p-treetable-footer{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-treetable .p-treetable-thead>tr>th{
    text-align:left;
    padding:1rem 1rem;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    font-weight:700;
    color:#708da9;
    background:#eff3f8;
    transition:box-shadow .2s
}
.p-treetable .p-treetable-tfoot>tr>td{
    text-align:left;
    padding:1rem 1rem;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    font-weight:700;
    color:#708da9;
    background:#eff3f8
}
.p-treetable .p-sortable-column{
    outline-color:#c7bbfa
}
.p-treetable .p-sortable-column .p-sortable-column-icon{
    color:#708da9;
    margin-left:.5rem
}
.p-treetable .p-sortable-column .p-sortable-column-badge{
    border-radius:50%;
    height:1.143rem;
    min-width:1.143rem;
    line-height:1.143rem;
    color:#7254f3;
    background:#e2dcfc;
    margin-left:.5rem
}
.p-treetable .p-sortable-column:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#708da9
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon{
    color:#708da9
}
.p-treetable .p-sortable-column.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon{
    color:#7254f3
}
.p-treetable .p-treetable-tbody>tr{
    background:#fff;
    color:#043d75;
    transition:box-shadow .2s
}
.p-treetable .p-treetable-tbody>tr>td{
    text-align:left;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem
}
.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s;
    margin-right:.5rem
}
.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler.p-icon{
    width:2rem;
    height:2rem
}
.p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox{
    margin-right:.5rem
}
.p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon{
    color:#043d75
}
.p-treetable .p-treetable-tbody>tr:focus{
    outline:.15rem solid #c7bbfa;
    outline-offset:-0.15rem
}
.p-treetable .p-treetable-tbody>tr.p-highlight{
    background:#e2dcfc;
    color:#7254f3
}
.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler{
    color:#7254f3
}
.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover{
    color:#7254f3
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler{
    color:#043d75
}
.p-treetable .p-column-resizer-helper{
    background:#7254f3
}
.p-treetable .p-treetable-scrollable-header,.p-treetable .p-treetable-scrollable-footer{
    background:#eff3f8
}
.p-treetable .p-treetable-loading-icon{
    font-size:2rem
}
.p-treetable .p-treetable-loading-icon.p-icon{
    width:2rem;
    height:2rem
}
.p-treetable.p-treetable-gridlines .p-datatable-header{
    border-width:1px 1px 0 1px
}
.p-treetable.p-treetable-gridlines .p-treetable-footer{
    border-width:0 1px 1px 1px
}
.p-treetable.p-treetable-gridlines .p-treetable-top{
    border-width:0 1px 0 1px
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom{
    border-width:0 1px 1px 1px
}
.p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th{
    border-width:1px
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td{
    border-width:1px
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td{
    border-width:1px
}
.p-treetable.p-treetable-sm .p-treetable-header{
    padding:.875rem .875rem
}
.p-treetable.p-treetable-sm .p-treetable-thead>tr>th{
    padding:.5rem .5rem
}
.p-treetable.p-treetable-sm .p-treetable-tbody>tr>td{
    padding:.5rem .5rem
}
.p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td{
    padding:.5rem .5rem
}
.p-treetable.p-treetable-sm .p-treetable-footer{
    padding:.5rem .5rem
}
.p-treetable.p-treetable-lg .p-treetable-header{
    padding:1.25rem 1.25rem
}
.p-treetable.p-treetable-lg .p-treetable-thead>tr>th{
    padding:1.25rem 1.25rem
}
.p-treetable.p-treetable-lg .p-treetable-tbody>tr>td{
    padding:1.25rem 1.25rem
}
.p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td{
    padding:1.25rem 1.25rem
}
.p-treetable.p-treetable-lg .p-treetable-footer{
    padding:1.25rem 1.25rem
}
.p-virtualscroller .p-virtualscroller-header{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:1px 0 1px 0;
    padding:1rem 1rem;
    font-weight:700
}
.p-virtualscroller .p-virtualscroller-content{
    background:#fff;
    color:#043d75;
    border:0 none;
    padding:0
}
.p-virtualscroller .p-virtualscroller-footer{
    background:#eff3f8;
    color:#708da9;
    border:1px solid #dfe7ef;
    border-width:0 0 1px 0;
    padding:1rem 1rem;
    font-weight:700;
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px
}
.p-accordion .p-accordion-header .p-accordion-header-link{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    font-weight:700;
    border-radius:6px;
    transition:box-shadow .2s
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    margin-right:.5rem
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
    background:#f6f9fc;
    border-color:#dfe7ef;
    color:#708da9
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background:#eff3f8;
    border-color:#dfe7ef;
    color:#043d75;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    border-color:#dfe7ef;
    background:#f6f9fc;
    color:#043d75
}
.p-accordion .p-accordion-content{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    border-top:0;
    border-top-right-radius:0;
    border-top-left-radius:0;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-accordion p-accordiontab .p-accordion-tab{
    margin-bottom:4px
}
.p-card{
    background:#fff;
    color:#043d75;
    box-shadow:0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius:6px
}
.p-card .p-card-body{
    padding:1.25rem
}
.p-card .p-card-title{
    font-size:1.5rem;
    font-weight:700;
    margin-bottom:.5rem
}
.p-card .p-card-subtitle{
    font-weight:400;
    margin-bottom:.5rem;
    color:#708da9
}
.p-card .p-card-content{
    padding:1.25rem 0
}
.p-card .p-card-footer{
    padding:1.25rem 0 0 0
}
.p-divider .p-divider-content{
    background-color:#fff
}
.p-divider.p-divider-horizontal{
    margin:1.25rem 0;
    padding:0 1.25rem
}
.p-divider.p-divider-horizontal:before{
    border-top:1px #dfe7ef
}
.p-divider.p-divider-horizontal .p-divider-content{
    padding:0 .5rem
}
.p-divider.p-divider-vertical{
    margin:0 1.25rem;
    padding:1.25rem 0
}
.p-divider.p-divider-vertical:before{
    border-left:1px #dfe7ef
}
.p-divider.p-divider-vertical .p-divider-content{
    padding:.5rem 0
}
.p-fieldset{
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    border-radius:6px
}
.p-fieldset .p-fieldset-legend{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    font-weight:700;
    border-radius:6px
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend{
    padding:0;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a{
    padding:1.25rem;
    color:#708da9;
    border-radius:6px;
    transition:box-shadow .2s
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler{
    margin-right:.5rem
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover{
    background:#f6f9fc;
    border-color:#dfe7ef;
    color:#708da9
}
.p-fieldset .p-fieldset-content{
    padding:1.25rem
}
.p-panel .p-panel-header{
    border:1px solid #dfe7ef;
    padding:1.25rem;
    background:#eff3f8;
    color:#708da9;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-panel .p-panel-header .p-panel-title{
    font-weight:700
}
.p-panel .p-panel-header .p-panel-header-icon{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-panel .p-panel-header .p-panel-header-icon:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-panel.p-panel-toggleable .p-panel-header{
    padding:.75rem 1.25rem
}
.p-panel .p-panel-content{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px;
    border-top:0 none
}
.p-panel .p-panel-footer{
    padding:.75rem 1.25rem;
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    border-top:0 none
}
.p-panel .p-panel-icons-end{
    order:2;
    margin-left:auto
}
.p-panel .p-panel-icons-start{
    order:0;
    margin-right:.5rem
}
.p-panel .p-panel-icons-center{
    order:2;
    width:100%;
    text-align:center
}
.p-scrollpanel .p-scrollpanel-bar{
    background:#eff3f8;
    border:0 none
}
.p-splitter{
    border:1px solid #dfe7ef;
    background:#fff;
    border-radius:6px;
    color:#043d75
}
.p-splitter .p-splitter-gutter{
    transition:background-color .2s,color .2s,box-shadow .2s;
    background:#eff3f8
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle{
    background:#dfe7ef
}
.p-splitter .p-splitter-gutter-resizing{
    background:#dfe7ef
}
.p-tabview .p-tabview-nav{
    background:#fff;
    border:1px solid #dfe7ef;
    border-width:0 0 2px 0
}
.p-tabview .p-tabview-nav li{
    margin-right:0
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    border:solid #dfe7ef;
    border-width:0 0 2px 0;
    border-color:transparent transparent #dfe7ef transparent;
    background:#fff;
    color:#708da9;
    padding:1.25rem;
    font-weight:700;
    border-top-right-radius:6px;
    border-top-left-radius:6px;
    transition:box-shadow .2s;
    margin:0 0 -2px 0
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 1px #c7bbfa
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link{
    background:#fff;
    border-color:#c6ccd7;
    color:#708da9
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    background:#fff;
    border-color:#7254f3;
    color:#7254f3
}
.p-tabview .p-tabview-left-icon{
    margin-right:.5rem
}
.p-tabview .p-tabview-right-icon{
    margin-left:.5rem
}
.p-tabview .p-tabview-close{
    margin-left:.5rem
}
.p-tabview .p-tabview-nav-btn.p-link{
    background:#fff;
    color:#7254f3;
    width:3rem;
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    border-radius:0
}
.p-tabview .p-tabview-nav-btn.p-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 1px #c7bbfa
}
.p-tabview .p-tabview-panels{
    background:#fff;
    padding:1.25rem;
    border:0 none;
    color:#043d75;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-toolbar{
    background:#eff3f8;
    border:1px solid #dfe7ef;
    padding:1.25rem;
    border-radius:6px;
    gap:.5rem
}
.p-toolbar .p-toolbar-separator{
    margin:0 .5rem
}
.p-confirm-popup{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 1px 3px rgba(0,0,0,.3)
}
.p-confirm-popup .p-confirm-popup-content{
    padding:1.25rem
}
.p-confirm-popup .p-confirm-popup-footer{
    text-align:right;
    padding:.75rem 1.25rem
}
.p-confirm-popup .p-confirm-popup-footer button{
    margin:0 .5rem 0 0;
    width:auto
}
.p-confirm-popup .p-confirm-popup-footer button:last-child{
    margin:0
}
.p-confirm-popup:after{
    border:solid transparent;
    border-color:rgba(255,255,255,0);
    border-bottom-color:#fff
}
.p-confirm-popup:before{
    border:solid transparent;
    border-color:rgba(255,255,255,0);
    border-bottom-color:#fff
}
.p-confirm-popup.p-confirm-popup-flipped:after{
    border-top-color:#fff
}
.p-confirm-popup.p-confirm-popup-flipped:before{
    border-top-color:#fff
}
.p-confirm-popup .p-confirm-popup-icon{
    font-size:1.5rem
}
.p-confirm-popup .p-confirm-popup-icon.p-icon{
    width:1.5rem;
    height:1.5rem
}
.p-confirm-popup .p-confirm-popup-message{
    margin-left:1rem
}
.p-dialog{
    border-radius:6px;
    box-shadow:0 1px 3px rgba(0,0,0,.3);
    border:0 none
}
.p-dialog .p-dialog-header{
    border-bottom:0 none;
    background:#fff;
    color:#708da9;
    padding:1.5rem;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-dialog .p-dialog-header .p-dialog-title{
    font-weight:700;
    font-size:1.25rem
}
.p-dialog .p-dialog-header .p-dialog-header-icon{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s;
    margin-right:.5rem
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child{
    margin-right:0
}
.p-dialog .p-dialog-content{
    background:#fff;
    color:#043d75;
    padding:0 1.5rem 2rem 1.5rem
}
.p-dialog .p-dialog-content:last-of-type{
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-dialog .p-dialog-footer{
    border-top:0 none;
    background:#fff;
    color:#043d75;
    padding:0 1.5rem 1.5rem 1.5rem;
    text-align:right;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-dialog .p-dialog-footer button{
    margin:0 .5rem 0 0;
    width:auto
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon{
    font-size:2rem
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon{
    width:2rem;
    height:2rem
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message{
    margin-left:1rem
}
.p-overlaypanel{
    background:#fff;
    color:#043d75;
    border:0 none;
    border-radius:6px;
    box-shadow:0 1px 3px rgba(0,0,0,.3)
}
.p-overlaypanel .p-overlaypanel-content{
    padding:1.25rem
}
.p-overlaypanel .p-overlaypanel-close{
    background:#7254f3;
    color:#fff;
    width:2rem;
    height:2rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:50%;
    position:absolute;
    top:-1rem;
    right:-1rem
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover{
    background:#6545f2;
    color:#fff
}
.p-overlaypanel:after{
    border:solid transparent;
    border-color:rgba(255,255,255,0);
    border-bottom-color:#fff
}
.p-overlaypanel:before{
    border:solid transparent;
    border-color:rgba(255,255,255,0);
    border-bottom-color:#f2f2f2
}
.p-overlaypanel.p-overlaypanel-flipped:after{
    border-top-color:#fff
}
.p-overlaypanel.p-overlaypanel-flipped:before{
    border-top-color:#fff
}
.p-sidebar{
    background:#fff;
    color:#043d75;
    border:0 none;
    box-shadow:0 1px 3px rgba(0,0,0,.3)
}
.p-sidebar .p-sidebar-header{
    padding:1.25rem
}
.p-sidebar .p-sidebar-header .p-sidebar-close,.p-sidebar .p-sidebar-header .p-sidebar-icon{
    width:2rem;
    height:2rem;
    color:#708da9;
    border:0 none;
    background:transparent;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover{
    color:#043d75;
    border-color:transparent;
    background:#f6f9fc
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,.p-sidebar .p-sidebar-header .p-sidebar-icon:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-sidebar .p-sidebar-header+.p-sidebar-content{
    padding-top:0
}
.p-sidebar .p-sidebar-content{
    padding:1.25rem
}
.p-sidebar .p-sidebar-footer{
    padding:1.25rem
}
.p-tooltip .p-tooltip-text{
    background:#043d75;
    color:#fff;
    padding:.75rem .75rem;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow{
    border-right-color:#043d75
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow{
    border-left-color:#043d75
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow{
    border-top-color:#043d75
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow{
    border-bottom-color:#043d75
}
.p-fileupload .p-fileupload-buttonbar{
    background:#eff3f8;
    padding:1.25rem;
    border:1px solid #dfe7ef;
    color:#708da9;
    border-bottom:0 none;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-fileupload .p-fileupload-buttonbar .p-button{
    margin-right:.5rem
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-fileupload .p-fileupload-content{
    background:#fff;
    padding:2rem 1rem;
    border:1px solid #dfe7ef;
    color:#043d75;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-fileupload .p-progressbar{
    height:.25rem
}
.p-fileupload .p-fileupload-row>div{
    padding:1rem 1rem
}
.p-fileupload.p-fileupload-advanced .p-message{
    margin-top:0
}
.p-fileupload-choose:not(.p-disabled):hover{
    background:#6545f2;
    color:#fff;
    border-color:#6545f2
}
.p-fileupload-choose:not(.p-disabled):active{
    background:#5935f1;
    color:#fff;
    border-color:#5935f1
}
.p-breadcrumb{
    background:#eff3f8;
    border:1px solid #dfe7ef;
    border-radius:6px;
    padding:1rem
}
.p-breadcrumb ul li .p-menuitem-link{
    transition:box-shadow .2s;
    border-radius:6px
}
.p-breadcrumb ul li .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon{
    color:#708da9
}
.p-breadcrumb ul li.p-breadcrumb-chevron{
    margin:0 .5rem 0 .5rem;
    color:#043d75
}
.p-breadcrumb ul li:last-child .p-menuitem-text{
    color:#043d75
}
.p-breadcrumb ul li:last-child .p-menuitem-icon{
    color:#708da9
}
.p-contextmenu{
    padding:.25rem 0;
    background:#eff3f8;
    color:#043d75;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px;
    width:12.5rem
}
.p-contextmenu .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-contextmenu .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-contextmenu .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-contextmenu .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-contextmenu .p-submenu-list{
    padding:.25rem 0;
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px
}
.p-contextmenu .p-menuitem{
    margin:0
}
.p-contextmenu .p-menuitem:last-child{
    margin:0
}
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link{
    background:#e2dcfc
}
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text{
    color:#7254f3
}
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon{
    color:#7254f3
}
.p-contextmenu .p-menu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-contextmenu .p-submenu-icon{
    font-size:.875rem
}
.p-contextmenu .p-icon{
    width:.875rem;
    height:.875rem
}
.p-contextmenu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-dock .p-dock-list{
    background:rgba(255,255,255,.1);
    border:1px solid rgba(255,255,255,.2);
    padding:.5rem .5rem;
    border-radius:.5rem
}
.p-dock .p-dock-item{
    padding:.5rem
}
.p-dock .p-dock-action{
    width:4rem;
    height:4rem
}
.p-dock.p-dock-top .p-dock-item-second-prev,.p-dock.p-dock-top .p-dock-item-second-next,.p-dock.p-dock-bottom .p-dock-item-second-prev,.p-dock.p-dock-bottom .p-dock-item-second-next{
    margin:0 .9rem
}
.p-dock.p-dock-top .p-dock-item-prev,.p-dock.p-dock-top .p-dock-item-next,.p-dock.p-dock-bottom .p-dock-item-prev,.p-dock.p-dock-bottom .p-dock-item-next{
    margin:0 1.3rem
}
.p-dock.p-dock-top .p-dock-item-current,.p-dock.p-dock-bottom .p-dock-item-current{
    margin:0 1.5rem
}
.p-dock.p-dock-left .p-dock-item-second-prev,.p-dock.p-dock-left .p-dock-item-second-next,.p-dock.p-dock-right .p-dock-item-second-prev,.p-dock.p-dock-right .p-dock-item-second-next{
    margin:.9rem 0
}
.p-dock.p-dock-left .p-dock-item-prev,.p-dock.p-dock-left .p-dock-item-next,.p-dock.p-dock-right .p-dock-item-prev,.p-dock.p-dock-right .p-dock-item-next{
    margin:1.3rem 0
}
.p-dock.p-dock-left .p-dock-item-current,.p-dock.p-dock-right .p-dock-item-current{
    margin:1.5rem 0
}
@media screen and (max-width: 960px){
    .p-dock.p-dock-top .p-dock-list-container,.p-dock.p-dock-bottom .p-dock-list-container{
        overflow-x:auto;
        width:100%
    }
    .p-dock.p-dock-top .p-dock-list-container .p-dock-list,.p-dock.p-dock-bottom .p-dock-list-container .p-dock-list{
        margin:0 auto
    }
    .p-dock.p-dock-left .p-dock-list-container,.p-dock.p-dock-right .p-dock-list-container{
        overflow-y:auto;
        height:100%
    }
    .p-dock.p-dock-left .p-dock-list-container .p-dock-list,.p-dock.p-dock-right .p-dock-list-container .p-dock-list{
        margin:auto 0
    }
    .p-dock .p-dock-list .p-dock-item{
        transform:none;
        margin:0
    }
}
.p-megamenu{
    padding:.5rem;
    background:#eff3f8;
    color:#043d75;
    border:1px solid #dfe7ef;
    border-radius:6px
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:6px;
    transition:box-shadow .2s;
    user-select:none
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon{
    color:#708da9;
    margin-left:.5rem
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover{
    background:#e2dcfc
}
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#7254f3
}
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#7254f3
}
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#7254f3
}
.p-megamenu .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-megamenu .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-megamenu .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-megamenu .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-megamenu .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-megamenu .p-megamenu-panel{
    background:#eff3f8;
    color:#043d75;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-megamenu .p-megamenu-submenu-header{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700;
    border-top-right-radius:6px;
    border-top-left-radius:6px
}
.p-megamenu .p-megamenu-submenu{
    padding:.25rem 0;
    width:12.5rem
}
.p-megamenu .p-megamenu-submenu .p-menu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-megamenu .p-megamenu-submenu .p-menuitem{
    margin:0
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child{
    margin:0
}
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link{
    background:#e2dcfc
}
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text{
    color:#7254f3
}
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon{
    color:#7254f3
}
.p-megamenu.p-megamenu-vertical{
    width:12.5rem;
    padding:.25rem 0
}
.p-megamenu.p-megamenu-vertical .p-menuitem{
    margin:0
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child{
    margin:0
}
.p-megamenu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-menu{
    padding:.25rem 0;
    background:#eff3f8;
    color:#043d75;
    border:1px solid #dfe7ef;
    border-radius:6px;
    width:12.5rem
}
.p-menu .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-menu .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-menu .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-menu .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-menu .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-menu .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-menu.p-menu-overlay{
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-menu .p-submenu-header{
    margin:0;
    padding:.75rem 1.25rem;
    color:#708da9;
    background:#eff3f8;
    font-weight:700;
    border-top-right-radius:0;
    border-top-left-radius:0
}
.p-menu .p-menu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-menu .p-menuitem{
    margin:0
}
.p-menu .p-menuitem:last-child{
    margin:0
}
.p-menu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-menubar{
    padding:.5rem;
    background:#eff3f8;
    color:#043d75;
    border:1px solid #dfe7ef;
    border-radius:6px
}
.p-menubar .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-menubar .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-menubar .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-menubar .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-menubar .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:6px;
    transition:box-shadow .2s;
    user-select:none
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon{
    color:#708da9;
    margin-left:.5rem
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover{
    background:#e2dcfc
}
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#7254f3
}
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#7254f3
}
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#7254f3
}
.p-menubar .p-submenu-list{
    padding:.25rem 0;
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    width:12.5rem
}
.p-menubar .p-submenu-list .p-menu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-menubar .p-submenu-list .p-submenu-icon{
    font-size:.875rem
}
.p-menubar .p-submenu-list .p-icon{
    width:.875rem;
    height:.875rem
}
.p-menubar .p-submenu-list .p-menuitem{
    margin:0
}
.p-menubar .p-submenu-list .p-menuitem:last-child{
    margin:0
}
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link{
    background:#e2dcfc
}
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text{
    color:#7254f3
}
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon{
    color:#7254f3
}
.p-menubar .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
@media screen and (max-width: 960px){
    .p-menubar{
        position:relative
    }
    .p-menubar .p-menubar-button{
        display:flex;
        width:2rem;
        height:2rem;
        color:#708da9;
        border-radius:50%;
        transition:background-color .2s,color .2s,box-shadow .2s
    }
    .p-menubar .p-menubar-button:hover{
        color:#708da9;
        background:#f6f9fc
    }
    .p-menubar .p-menubar-button:focus{
        outline:0 none;
        outline-offset:0;
        box-shadow:0 0 0 1px #c7bbfa
    }
    .p-menubar .p-menubar-root-list{
        position:absolute;
        display:none;
        padding:.25rem 0;
        background:#eff3f8;
        border:0 none;
        box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
        width:100%
    }
    .p-menubar .p-menubar-root-list .p-menu-separator{
        border-top:1px solid #dfe7ef;
        margin:.25rem 0
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon{
        font-size:.875rem
    }
    .p-menubar .p-menubar-root-list>.p-menuitem{
        width:100%;
        position:static
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link{
        padding:.75rem 1.25rem;
        color:#043d75;
        border-radius:0;
        transition:box-shadow .2s;
        user-select:none
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text{
        color:#043d75
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon{
        color:#708da9;
        margin-right:.5rem
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon{
        color:#708da9
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover{
        background:#f6f9fc
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
        color:#043d75
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
        color:#708da9
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
        color:#708da9
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus{
        outline:0 none;
        outline-offset:0;
        box-shadow:inset 0 0 0 .15rem #c7bbfa
    }
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon{
        margin-left:auto;
        transition:transform .2s
    }
    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon{
        transform:rotate(-180deg)
    }
    .p-menubar .p-menubar-root-list .p-submenu-list{
        width:100%;
        position:static;
        box-shadow:none;
        border:0 none
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon{
        transition:transform .2s;
        transform:rotate(90deg)
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon{
        transform:rotate(-90deg)
    }
    .p-menubar .p-menubar-root-list .p-menuitem{
        width:100%;
        position:static
    }
    .p-menubar .p-menubar-root-list ul li a{
        padding-left:2.25rem
    }
    .p-menubar .p-menubar-root-list ul li ul li a{
        padding-left:3.75rem
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li a{
        padding-left:5.25rem
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a{
        padding-left:6.75rem
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a{
        padding-left:8.25rem
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list{
        display:flex;
        flex-direction:column;
        top:100%;
        left:0;
        z-index:1
    }
}
.p-panelmenu .p-panelmenu-header>a{
    padding:1.25rem;
    border:1px solid #dfe7ef;
    color:#708da9;
    background:#eff3f8;
    font-weight:700;
    border-radius:6px;
    transition:box-shadow .2s
}
.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon{
    margin-right:.5rem
}
.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon{
    margin-right:.5rem
}
.p-panelmenu .p-panelmenu-header>a:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover{
    background:#f6f9fc;
    border-color:#dfe7ef;
    color:#708da9
}
.p-panelmenu .p-panelmenu-header.p-highlight{
    margin-bottom:0
}
.p-panelmenu .p-panelmenu-header.p-highlight>a{
    background:#eff3f8;
    border-color:#dfe7ef;
    color:#043d75;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover{
    border-color:#dfe7ef;
    background:#f6f9fc;
    color:#043d75
}
.p-panelmenu .p-panelmenu-content{
    padding:.25rem 0;
    border:1px solid #dfe7ef;
    background:#fff;
    color:#043d75;
    margin-bottom:4px;
    border-top:0;
    border-top-right-radius:0;
    border-top-left-radius:0;
    border-bottom-right-radius:6px;
    border-bottom-left-radius:6px
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon{
    margin-right:.5rem
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu){
    padding:0 0 0 1rem
}
.p-panelmenu .p-panelmenu-panel{
    margin-bottom:4px
}
.p-panelmenu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-slidemenu{
    padding:.25rem 0;
    background:#eff3f8;
    color:#043d75;
    border:1px solid #dfe7ef;
    border-radius:6px;
    width:12.5rem
}
.p-slidemenu .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-slidemenu .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-slidemenu .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-slidemenu .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-slidemenu.p-slidemenu-overlay{
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-slidemenu .p-slidemenu-list{
    padding:.25rem 0;
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link{
    background:#e2dcfc
}
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-text{
    color:#7254f3
}
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-icon,.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link .p-slidemenu-icon{
    color:#7254f3
}
.p-slidemenu .p-slidemenu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-slidemenu .p-slidemenu-icon{
    font-size:.875rem
}
.p-slidemenu .p-icon{
    width:.875rem;
    height:.875rem
}
.p-slidemenu .p-slidemenu-backward{
    padding:.75rem 1.25rem;
    color:#043d75
}
.p-slidemenu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-steps .p-steps-item .p-menuitem-link{
    background:transparent;
    transition:box-shadow .2s;
    border-radius:6px;
    background:#fff
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number{
    color:#043d75;
    border:1px solid #f6f9fc;
    background:#fff;
    min-width:2rem;
    height:2rem;
    line-height:2rem;
    font-size:1.143rem;
    z-index:1;
    border-radius:50%
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title{
    margin-top:.5rem;
    color:#708da9
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-steps .p-steps-item.p-highlight .p-steps-number{
    background:#e2dcfc;
    color:#7254f3
}
.p-steps .p-steps-item.p-highlight .p-steps-title{
    font-weight:700;
    color:#043d75
}
.p-steps .p-steps-item:before{
    content:" ";
    border-top:1px solid #dfe7ef;
    width:100%;
    top:50%;
    left:0;
    display:block;
    position:absolute;
    margin-top:-1rem
}
.p-tabmenu .p-tabmenu-nav{
    background:#fff;
    border:1px solid #dfe7ef;
    border-width:0 0 2px 0
}
.p-tabmenu .p-tabmenu-nav .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem{
    margin-right:0
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    border:solid #dfe7ef;
    border-width:0 0 2px 0;
    border-color:transparent transparent #dfe7ef transparent;
    background:#fff;
    color:#708da9;
    padding:1.25rem;
    font-weight:700;
    border-top-right-radius:6px;
    border-top-left-radius:6px;
    transition:box-shadow .2s;
    margin:0 0 -2px 0
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon{
    margin-right:.5rem
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 1px #c7bbfa
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link{
    background:#fff;
    border-color:#c6ccd7;
    color:#708da9
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    background:#fff;
    border-color:#7254f3;
    color:#7254f3
}
.p-tabmenu .p-tabmenu-left-icon{
    margin-right:.5rem
}
.p-tabmenu .p-tabmenu-right-icon{
    margin-left:.5rem
}
.p-tabmenu .p-tabmenu-nav-btn.p-link{
    background:#fff;
    color:#7254f3;
    width:3rem;
    box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    border-radius:0
}
.p-tabmenu .p-tabmenu-nav-btn.p-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 1px #c7bbfa
}
.p-tieredmenu{
    padding:.25rem 0;
    background:#eff3f8;
    color:#043d75;
    border:1px solid #dfe7ef;
    border-radius:6px;
    width:12.5rem
}
.p-tieredmenu .p-menuitem-link{
    padding:.75rem 1.25rem;
    color:#043d75;
    border-radius:0;
    transition:box-shadow .2s;
    user-select:none
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text{
    color:#043d75
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon{
    color:#708da9;
    margin-right:.5rem
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon{
    color:#708da9
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover{
    background:#f6f9fc
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
    color:#043d75
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{
    color:#708da9
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon{
    color:#708da9
}
.p-tieredmenu .p-menuitem-link:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:inset 0 0 0 .15rem #c7bbfa
}
.p-tieredmenu.p-tieredmenu-overlay{
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-tieredmenu .p-submenu-list{
    padding:.25rem 0;
    background:#eff3f8;
    border:0 none;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1)
}
.p-tieredmenu .p-menuitem{
    margin:0
}
.p-tieredmenu .p-menuitem:last-child{
    margin:0
}
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link{
    background:#e2dcfc
}
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text{
    color:#7254f3
}
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon{
    color:#7254f3
}
.p-tieredmenu .p-menu-separator{
    border-top:1px solid #dfe7ef;
    margin:.25rem 0
}
.p-tieredmenu .p-submenu-icon{
    font-size:.875rem
}
.p-tieredmenu .p-icon{
    width:.875rem;
    height:.875rem
}
.p-tieredmenu .p-menuitem-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem;
    border-radius:6px;
    margin-left:.5rem;
    padding-left:.5rem;
    padding-right:.5rem
}
.p-inline-message{
    padding:.75rem .75rem;
    margin:0;
    border-radius:6px
}
.p-inline-message.p-inline-message-info{
    background:#e9e9ff;
    border:solid #696cff;
    border-width:0px;
    color:#696cff
}
.p-inline-message.p-inline-message-info .p-inline-message-icon{
    color:#696cff
}
.p-inline-message.p-inline-message-success{
    background:#e4f8f0;
    border:solid #1ea97c;
    border-width:0px;
    color:#1ea97c
}
.p-inline-message.p-inline-message-success .p-inline-message-icon{
    color:#1ea97c
}
.p-inline-message.p-inline-message-warn{
    background:#fff2e2;
    border:solid #cc8925;
    border-width:0px;
    color:#cc8925
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon{
    color:#cc8925
}
.p-inline-message.p-inline-message-error{
    background:#ffe7e6;
    border:solid #ff5757;
    border-width:0px;
    color:#ff5757
}
.p-inline-message.p-inline-message-error .p-inline-message-icon{
    color:#ff5757
}
.p-inline-message .p-inline-message-icon{
    font-size:1rem;
    margin-right:.5rem
}
.p-inline-message .p-icon{
    width:1rem;
    height:1rem
}
.p-inline-message .p-inline-message-text{
    font-size:1rem
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon{
    margin-right:0
}
.p-message{
    margin:1rem 0;
    border-radius:6px
}
.p-message .p-message-wrapper{
    padding:1.25rem 1.75rem
}
.p-message .p-message-close{
    width:2rem;
    height:2rem;
    border-radius:50%;
    background:transparent;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-message .p-message-close:hover{
    background:rgba(255,255,255,.3)
}
.p-message .p-message-close:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-message.p-message-info{
    background:#e9e9ff;
    border:solid #696cff;
    border-width:0 0 0 6px;
    color:#696cff
}
.p-message.p-message-info .p-message-icon{
    color:#696cff
}
.p-message.p-message-info .p-message-close{
    color:#696cff
}
.p-message.p-message-success{
    background:#e4f8f0;
    border:solid #1ea97c;
    border-width:0 0 0 6px;
    color:#1ea97c
}
.p-message.p-message-success .p-message-icon{
    color:#1ea97c
}
.p-message.p-message-success .p-message-close{
    color:#1ea97c
}
.p-message.p-message-warn{
    background:#fff2e2;
    border:solid #cc8925;
    border-width:0 0 0 6px;
    color:#cc8925
}
.p-message.p-message-warn .p-message-icon{
    color:#cc8925
}
.p-message.p-message-warn .p-message-close{
    color:#cc8925
}
.p-message.p-message-error{
    background:#ffe7e6;
    border:solid #ff5757;
    border-width:0 0 0 6px;
    color:#ff5757
}
.p-message.p-message-error .p-message-icon{
    color:#ff5757
}
.p-message.p-message-error .p-message-close{
    color:#ff5757
}
.p-message .p-message-text{
    font-size:1rem;
    font-weight:400
}
.p-message .p-message-icon{
    font-size:1.5rem;
    margin-right:.5rem
}
.p-message .p-icon{
    width:1.5rem;
    height:1.5rem
}
.p-message .p-message-summary{
    font-weight:700
}
.p-message .p-message-detail{
    margin-left:.5rem
}
.p-toast{
    opacity:.9
}
.p-toast .p-toast-message{
    margin:0 0 1rem 0;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    border-radius:6px
}
.p-toast .p-toast-message .p-toast-message-content{
    padding:1rem;
    border-width:0 0 0 6px
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text{
    margin:0 0 0 1rem
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon{
    font-size:2rem
}
.p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon){
    width:2rem;
    height:2rem
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary{
    font-weight:700
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail{
    margin:.5rem 0 0 0
}
.p-toast .p-toast-message .p-toast-icon-close{
    width:2rem;
    height:2rem;
    border-radius:50%;
    background:transparent;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-toast .p-toast-message .p-toast-icon-close:hover{
    background:rgba(255,255,255,.3)
}
.p-toast .p-toast-message .p-toast-icon-close:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-toast .p-toast-message.p-toast-message-info{
    background:#e9e9ff;
    border:solid #696cff;
    border-width:0 0 0 6px;
    color:#696cff
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close{
    color:#696cff
}
.p-toast .p-toast-message.p-toast-message-success{
    background:#e4f8f0;
    border:solid #1ea97c;
    border-width:0 0 0 6px;
    color:#1ea97c
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close{
    color:#1ea97c
}
.p-toast .p-toast-message.p-toast-message-warn{
    background:#fff2e2;
    border:solid #cc8925;
    border-width:0 0 0 6px;
    color:#cc8925
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close{
    color:#cc8925
}
.p-toast .p-toast-message.p-toast-message-error{
    background:#ffe7e6;
    border:solid #ff5757;
    border-width:0 0 0 6px;
    color:#ff5757
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close{
    color:#ff5757
}
.p-galleria .p-galleria-close{
    margin:.5rem;
    background:transparent;
    color:#eff3f8;
    width:4rem;
    height:4rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:50%
}
.p-galleria .p-galleria-close .p-galleria-close-icon{
    font-size:2rem
}
.p-galleria .p-galleria-close .p-icon-wrapper .p-icon{
    width:2rem;
    height:2rem
}
.p-galleria .p-galleria-close:hover{
    background:rgba(255,255,255,.1);
    color:#eff3f8
}
.p-galleria .p-galleria-item-nav{
    background:transparent;
    color:#eff3f8;
    width:4rem;
    height:4rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:6px;
    margin:0 .5rem
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon{
    font-size:2rem
}
.p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon{
    width:2rem;
    height:2rem
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover{
    background:rgba(255,255,255,.1);
    color:#eff3f8
}
.p-galleria .p-galleria-caption{
    background:rgba(0,0,0,.5);
    color:#eff3f8;
    padding:1rem
}
.p-galleria .p-galleria-indicators{
    padding:1rem
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button{
    background-color:#d3dbe3;
    width:1rem;
    height:1rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:50%
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover{
    background:#c6ccd7
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button{
    background:#e2dcfc;
    color:#7254f3
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,.p-galleria.p-galleria-indicators-top .p-galleria-indicator{
    margin-right:.5rem
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,.p-galleria.p-galleria-indicators-right .p-galleria-indicator{
    margin-bottom:.5rem
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators{
    background:rgba(0,0,0,.5)
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button{
    background:rgba(255,255,255,.4)
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover{
    background:rgba(255,255,255,.6)
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button{
    background:#e2dcfc;
    color:#7254f3
}
.p-galleria .p-galleria-thumbnail-container{
    background:rgba(0,0,0,.9);
    padding:1rem .25rem
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next{
    margin:.5rem;
    background-color:transparent;
    color:#eff3f8;
    width:2rem;
    height:2rem;
    transition:background-color .2s,color .2s,box-shadow .2s;
    border-radius:50%
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover{
    background:rgba(255,255,255,.1);
    color:#eff3f8
}
.p-galleria-mask{
    --maskbg: rgba(0, 0, 0, 0.9)
}
.p-image-mask{
    --maskbg: rgba(0, 0, 0, 0.9)
}
.p-image-preview-indicator{
    background-color:transparent;
    color:#f8f9fa;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-image-preview-container:hover>.p-image-preview-indicator{
    background-color:rgba(0,0,0,.5)
}
.p-image-toolbar{
    padding:1rem
}
.p-image-action.p-link{
    color:#f8f9fa;
    background-color:transparent;
    width:3rem;
    height:3rem;
    border-radius:50%;
    transition:background-color .2s,color .2s,box-shadow .2s;
    margin-right:.5rem
}
.p-image-action.p-link:last-child{
    margin-right:0
}
.p-image-action.p-link:hover{
    color:#f8f9fa;
    background-color:rgba(255,255,255,.1)
}
.p-image-action.p-link i{
    font-size:1.5rem
}
.p-image-action.p-link .p-icon{
    width:1.5rem;
    height:1.5rem
}
.p-avatar{
    background-color:#dfe7ef;
    border-radius:6px
}
.p-avatar.p-avatar-lg{
    width:3rem;
    height:3rem;
    font-size:1.5rem
}
.p-avatar.p-avatar-lg .p-avatar-icon{
    font-size:1.5rem
}
.p-avatar.p-avatar-xl{
    width:4rem;
    height:4rem;
    font-size:2rem
}
.p-avatar.p-avatar-xl .p-avatar-icon{
    font-size:2rem
}
.p-avatar-group .p-avatar{
    border:2px solid #fff
}
.p-badge{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    min-width:1.5rem;
    height:1.5rem;
    line-height:1.5rem
}
.p-badge.p-badge-secondary{
    background-color:#a1c30d;
    color:#fff
}
.p-badge.p-badge-success{
    background-color:#29c76f;
    color:#fff
}
.p-badge.p-badge-info{
    background-color:#5486f3;
    color:#fff
}
.p-badge.p-badge-warning{
    background-color:#ff9f42;
    color:#212529
}
.p-badge.p-badge-danger{
    background-color:#ea5455;
    color:#fff
}
.p-badge.p-badge-lg{
    font-size:1.125rem;
    min-width:2.25rem;
    height:2.25rem;
    line-height:2.25rem
}
.p-badge.p-badge-xl{
    font-size:1.5rem;
    min-width:3rem;
    height:3rem;
    line-height:3rem
}
.p-chip{
    background-color:#dfe7ef;
    color:#043d75;
    border-radius:16px;
    padding:0 .75rem
}
.p-chip .p-chip-text{
    line-height:1.5;
    margin-top:.375rem;
    margin-bottom:.375rem
}
.p-chip .p-chip-icon{
    margin-right:.5rem
}
.p-chip .pi-chip-remove-icon{
    margin-left:.5rem
}
.p-chip img{
    width:2.25rem;
    height:2.25rem;
    margin-left:-0.75rem;
    margin-right:.5rem
}
.p-chip .pi-chip-remove-icon{
    border-radius:6px;
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-chip .pi-chip-remove-icon:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-inplace .p-inplace-display{
    padding:.75rem .75rem;
    border-radius:6px;
    transition:background-color .2s,color .2s,border-color .2s,box-shadow .2s
}
.p-inplace .p-inplace-display:not(.p-disabled):hover{
    background:#f6f9fc;
    color:#043d75
}
.p-inplace .p-inplace-display:focus{
    outline:0 none;
    outline-offset:0;
    box-shadow:0 0 0 1px #c7bbfa
}
.p-progressbar{
    border:0 none;
    height:1.5rem;
    background:#dfe7ef;
    border-radius:6px
}
.p-progressbar .p-progressbar-value{
    border:0 none;
    margin:0;
    background:#7254f3
}
.p-progressbar .p-progressbar-label{
    color:#fff;
    line-height:1.5rem
}
.p-scrolltop{
    width:3rem;
    height:3rem;
    border-radius:50%;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    transition:background-color .2s,color .2s,box-shadow .2s
}
.p-scrolltop.p-link{
    background:rgba(0,0,0,.7)
}
.p-scrolltop.p-link:hover{
    background:rgba(0,0,0,.8)
}
.p-scrolltop .p-scrolltop-icon{
    font-size:1.5rem;
    color:#eff3f8
}
.p-scrolltop .p-icon{
    width:1.5rem;
    height:1.5rem
}
.p-skeleton{
    background-color:#dfe7ef;
    border-radius:6px
}
.p-skeleton:after{
    background:linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
}
.p-tag{
    background:#7254f3;
    color:#fff;
    font-size:.75rem;
    font-weight:700;
    padding:.25rem .4rem;
    border-radius:6px
}
.p-tag.p-tag-success{
    background-color:#29c76f;
    color:#fff
}
.p-tag.p-tag-info{
    background-color:#5486f3;
    color:#fff
}
.p-tag.p-tag-warning{
    background-color:#ff9f42;
    color:#212529
}
.p-tag.p-tag-danger{
    background-color:#ea5455;
    color:#fff
}
.p-tag .p-tag-icon{
    margin-right:.25rem;
    font-size:.75rem
}
.p-tag .p-icon{
    width:.75rem;
    height:.75rem
}
.p-terminal{
    background:#fff;
    color:#043d75;
    border:1px solid #dfe7ef;
    padding:1.25rem
}
.p-terminal .p-terminal-input{
    font-size:1rem;
    font-family:Lato,Helvetica,sans-serif
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group{
    border-right:1px solid rgba(255,255,255,.2)
}
.p-datepicker .p-datepicker-buttonbar{
    border-top:1px solid rgba(255,255,255,.2)
}
.p-datepicker .p-datepicker-buttonbar .p-button{
    color:#fff
}
.p-datepicker .p-datepicker-buttonbar .p-button:enabled:hover{
    color:#fff;
    background-color:rgba(255,255,255,.2)
}
.p-datepicker .p-timepicker{
    border-top:1px solid rgba(255,255,255,.2)
}
.p-datepicker table{
    font-size:1rem;
    margin:.5rem 0
}
.p-datepicker table th{
    color:rgba(255,255,255,.7)
}
.p-datepicker table td>span.p-highlight{
    color:#fff;
    background:rgba(255,255,255,.3)
}
.p-datepicker table td.p-datepicker-today>span.p-highlight{
    color:#fff;
    background:rgba(255,255,255,.3)
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight{
    color:#fff;
    background:rgba(255,255,255,.3)
}
.p-button .p-button-label{
    font-weight:700
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background-color:#7254f3
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button{
    background-color:#7254f3
}
.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td{
    box-shadow:inset 0 2px 0 0 #7254f3
}
.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td{
    box-shadow:inset 0 -2px 0 0 #7254f3
}
