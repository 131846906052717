.marker-cluster{
    background-color: rgb(255, 113, 70, 0.6) !important;

    div{
        background-color: rgb(255, 113, 70, 0.6) !important;

        span{
            color: white;
            font-weight: 500;
        }
    }
}