/* montserrat-100 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-200 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-300 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-800 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-900 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-100italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-200italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-300italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-500italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-600italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-700italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-800italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }
  /* montserrat-900italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: url('/assets/fonts/montserrat/Montserrat-VariableFont.ttf') format("truetype")
  }